import Footer from "./Footer";

import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Studentevent.css";
import Header from "./Header";
import collaboration from "../../assets/images/Studentpage/collaboration.svg";
import mentalhealth from "../../assets/images/Studentpage/mentalhealth.svg";
import referencability from "../../assets/images/Studentpage/Increased.svg";
import Boosted from "../../assets/images/Studentpage/Boosted.svg";
import Career from "../../assets/images/Studentpage/Career.svg";
import connections from "../../assets/images/Studentpage/connections.svg";
import Improved from "../../assets/images/Studentpage/Improved.svg";
import campus from "../../assets/images/Studentpage/Campus.svg";
import Learning from "../../assets/images/Studentpage/Learning.svg";
import Breaking from "../../assets/images/Studentpage/Breaking.svg";
import Management from "../../assets/images/Studentpage/Management.svg";
import Transitions from "../../assets/images/Studentpage/Transitions.svg";
import GetTounch from "./GetTounch";
const StudentWhyConnectar = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);

  return (
    <>
      <Header />
      <section class="border1">
        <section className="container">
          <section className="row align-items-center">
            <section className="col-sm-12 bannerContent" data-aos="fade-right">
              <h2>
                {" "}
                Why <span className="heading-color"> The Connectar? </span>
              </h2>
              <p className="text-left font">
                Improved connection among students at universities and colleges offers a wide range
                of benefits, contributing to both their academic success and personal well-being.
                Here is a comprehensive list of reasons:
              </p>
            </section>
          </section>
        </section>
      </section>
      <section className="content-widget">
        <section className="container">
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img
                src={collaboration}
                alt=""
                style={{ height: "300px" }}
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7">
              <h2>
                1. Enhanced <span className="heading-color"> Academic Collaboration</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>Study Groups:</b> Students can form study groups that promote collaborative
                    learning, sharing diverse perspectives and ideas
                  </li>
                  <li>
                    <b>Peer Tutoring:</b> Stronger connections allow for peer tutoring opportunities
                    where students help each other, reinforcing their own knowledge.
                  </li>
                  <li>
                    <b>Academic Motivation:</b> A strong network can help students stay motivated by
                    encouraging academic goals and providing accountability.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img
                src={mentalhealth}
                alt=""
                style={{ height: "300px" }}
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>
                2.Improved<span className="heading-color"> Mental Health and Well-being</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>Emotional Support:</b> Having friends and a support network reduces feelings
                    of loneliness and homesickness.
                  </li>
                  <li>
                    <b>Stress Reduction:</b> Strong connections with peers create a safe environment
                    to vent frustrations and share coping strategies for handling academic and
                    personal stress.
                  </li>
                  <li>
                    <b>Increased Resilience:</b> Supportive relationships help students build
                    resilience, making them more likely to persevere through difficult times.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img
                src={referencability}
                alt=""
                style={{ height: "300px" }}
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7">
              <h2>
                3. Increased Retention
                <span className="heading-color"> and Graduation Rates </span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Sense of Belonging:</b> Students who feel connected are more likely to stay
                    enrolled and complete their degree, reducing dropout rates.
                  </li>
                  <li>
                    <b> Engagement in Campus Life: </b> Stronger connections lead to more active
                    involvement in extracurricular activities, enhancing the overall college
                    experience.
                  </li>
                  <li>
                    <b>Better Adjustment:</b> New students with strong social connections adapt more
                    easily to the university environment, making their transition smoother.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img
                src={Boosted}
                alt=""
                style={{ height: "300px" }}
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>
                4. Boosted <span className="heading-color">Social Skills</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>Improved Communication:</b> Regular interactions with a diverse group of
                    students help develop essential communication and interpersonal skills.
                  </li>
                  <li>
                    <b>Conflict Resolution:</b> Collaborative environments foster the development of
                    skills in resolving differences and building consensus.
                  </li>
                  <li>
                    <b> Leadership Development:</b> Strong networks provide opportunities for
                    students to take on leadership roles, whether in clubs, events, or group
                    projects.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img
                src={Career}
                alt=""
                style={{ height: "300px" }}
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7">
              <h2>
                5. Career <span className="heading-color">Advancement</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Networking Opportunities:</b> Peer connections often lead to networking
                    opportunities with industry professionals, internships, and job offers.
                  </li>
                  <li>
                    <b>Mentorship:</b> Students benefit from mentorship relationships, where older
                    students or alumni offer guidance on career paths, job applications, and
                    professional development.
                  </li>
                  <li>
                    <b>Collaborative Projects:</b> Building connections can lead to teamwork on
                    projects, research opportunities, or entrepreneurial ventures that enhance
                    resumes.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img
                src={connections}
                alt=""
                style={{ height: "300px" }}
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>
                6. Cultural Exchange <span className="heading-color"> and Inclusion </span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Diverse Perspectives:</b> Connections with students from diverse backgrounds
                    expose individuals to different cultures, worldviews, and experiences.
                  </li>
                  <li>
                    <b>Increased Tolerance: </b> Collaborative environments promote tolerance and
                    inclusivity, leading to a more respectful and welcoming campus culture.
                  </li>
                  <li>
                    <b>Global Connections:</b> Strong connections with international students foster
                    global awareness and open opportunities for study abroad programs or global
                    internships.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img
                src={Improved}
                alt=""
                style={{ height: "300px" }}
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7">
              <h2>
                7. Improved <span className="heading-color">Personal Growth</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Increased Confidence:</b> Meaningful relationships give students the support
                    to step out of their comfort zones, fostering personal development.
                  </li>
                  <li>
                    <b>Emotional Intelligence:</b> Connecting with others helps students develop
                    emotional intelligence, as they learn to empathize, listen, and respond to the
                    needs of others.
                  </li>
                  <li>
                    <b>Sense of Purpose:</b> Stronger connections to people who share common
                    interests can help students discover their passions and life goals.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img
                src={campus}
                alt=""
                style={{ height: "300px" }}
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>
                8. Increased Engagement in Campus and{" "}
                <span className="heading-color">Community Initiatives </span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Active Participation: </b> Connected students are more likely to engage in
                    clubs, societies, and community service, enriching their college experience.
                  </li>
                  <li>
                    <b> Leadership in Campus Organizations: </b>
                    Stronger connections foster students' involvement in leadership positions within
                    student government, clubs, or other organizations.
                  </li>
                  <li>
                    <b> Community Building:</b> Active student connections contribute to the overall
                    atmosphere of the campus, helping to cultivate a collaborative and unified
                    community
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img
                src={Learning}
                alt=""
                style={{ height: "300px" }}
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7">
              <h2>
                9.Improved <span className="heading-color">Learning Environment</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>Increased Interaction:</b> Strong connections encourage more interaction with
                    faculty, peers, and staff, enriching the learning environment.
                  </li>
                  <li>
                    <b> Collaborative Research:</b> Students who are well-connected may collaborate
                    with faculty and other students on research projects, enhancing their academic
                    experience.
                  </li>
                  <li>
                    <b> Resource Sharing:</b> Connected students share academic resources such as
                    study guides, textbooks, and notes, making learning more efficient and
                    accessible.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img
                src={Breaking}
                alt=""
                style={{ height: "300px" }}
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>
                10. Promotion of Diversity <span className="heading-color"> and Inclusion</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Breaking Down Barriers:</b> A focus on connection helps break down social
                    and cultural barriers, leading to a more inclusive and harmonious campus
                    environment.
                  </li>
                  <li>
                    <b>Empowerment of Minority Groups:</b> Connected student groups often work
                    toward inclusivity and social justice, helping marginalized students feel heard
                    and supported.
                  </li>
                  <li>
                    <b>Social Justice and Advocacy:</b> Stronger student networks often engage in
                    advocacy, pushing for changes in policies or support systems to benefit diverse
                    student populations.
                  </li>
                </ul>
              </p>
            </section>
          </section>

          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img
                src={Management}
                alt=""
                style={{ height: "300px" }}
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7">
              <h2>
                11.Better <span className="heading-color"> Time Management</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Collaboration in Time-Intensive Projects:</b> Working together on group
                    projects allows students to manage workloads more efficiently, dividing tasks
                    based on strengths and availability
                  </li>
                  <li>
                    <b> Sharing Organizational Tools:</b> Students connected through academic or
                    social networks can share organizational tools and tips to help each other
                    manage time better.
                  </li>
                </ul>
              </p>
            </section>
          </section>

          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img
                src={Transitions}
                alt=""
                style={{ height: "300px" }}
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>
                12.Support for <span className="heading-color"> a Career Transitions</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Alumni Networks:</b> Building connections with alumni can provide guidance
                    on transitioning from university to the professional world.
                  </li>
                  <li>
                    <b>Access to Job Opportunities:</b> Strong connections increase access to
                    internship and job opportunities through peer referrals and recommendations.
                  </li>
                  <li>
                    <b>Social Justice and Advocacy:</b> Stronger student networks often engage in
                    advocacy, pushing for changes in policies or support systems to benefit diverse
                    student populations.
                  </li>
                  <li>
                    By fostering meaningful connections among students, universities can create a
                    more supportive, inclusive, and academically enriching environment that benefits
                    both individuals and the larger university community.
                  </li>
                </ul>
              </p>
            </section>
          </section>
        </section>
      </section>
      <GetTounch />
      <div data-aos="fade-right" id="footer">
        <Footer />
      </div>
    </>
  );
};

export default StudentWhyConnectar;
