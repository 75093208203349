import { lazy, Suspense } from "react";

import { Routes, Route, Navigate } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";

import Basic from "layouts/authentication/sign-in";
import ResetPassword from "layouts/authentication/sign-in/ResetPassword";
import InviteLink from "layouts/authentication/sign-in/InviteLink";
import Forget from "layouts/authentication/sign-in/Forget";
import PublicRoutes from "./PublicRoutes";
import FrontPage from "components/Questionare/WelcomeScreen";
import PrivateCompanyRoutes from "./PrivateCompanyRoutes";
import PrivateUserRoutes from "./PrivateUserRoutes";
import PrivateAdminRoute from "./PrivateAdminRoute";
import PublicProfile from "components/UserDashboard/PublicProfile";
import Feedback from "components/Userinterests/Feedback";
import Snapshot from "layouts/Adminprofile/Snapshot";
import FeedbackAlluser from "layouts/Adminprofile/FeedbackAlluser";
import MainSection from "MainComponet/MainSection";
import WhyTheConnectar from "MainComponet/WhyTheConnectar";
import WhatTellUs from "MainComponet/WhatTellUs";
import Methodology from "MainComponet/Methodology";
import WhyNow from "MainComponet/WhyNow";
import ContactUs from "MainComponet/ContactUs";
import PrivacyStatement from "MainComponet/PrivacyStatement";
import { useLocation } from "react-router-dom";
// const CompaniesTable = lazy(() => import("layouts/Adminprofile/CompaniesTable"));
// const Categories = lazy(() => import("layouts/Category/Categories"));
// const AdminProfile = lazy(() => import("layouts/Adminprofile/Profile"));

// const UsersTable = lazy(() => import("components/CompanyDashboard/UsersTable"));
// const UploadUsers = lazy(() => import("components/CompanyDashboard/uploadUsers"));
// const CompanyProfile = lazy(() => import("components/CompanyDashboard/CompanyProfile"));

// const Connections = lazy(() => import("components/UserDashboard/Connection"));
// const UserProfile = lazy(() => import("components/UserDashboard/UserProfile"));
// const Questionere = lazy(() => import("components/Questionare/Questinere"));
import CompaniesTable from "layouts/Adminprofile/CompaniesTable";
import Categories from "layouts/Category/Categories";
import AdminProfile from "layouts/Adminprofile/Profile";
import UsersTable from "components/CompanyDashboard/UsersTable";
import UploadUsers from "components/CompanyDashboard/uploadUsers";
import CompanyProfile from "components/CompanyDashboard/CompanyProfile";
import Connections from "components/UserDashboard/Connection";
import UserProfile from "components/UserDashboard/UserProfile";
import Questionere from "components/Questionare/Questinere";
import "MainComponet/css/custom.css";
import PageList from "layouts/Adminprofile/CMSpages/customcms/components/admin/PageList";
import CreatePage from "layouts/Adminprofile/CMSpages/customcms/components/admin/CreatePage";
import EditPage from "layouts/Adminprofile/CMSpages/customcms/components/admin/EditPage";
import BlogContent from "MainComponet/BlogContent";
import PageTemplate from "layouts/Adminprofile/CMSpages/customcms/components/PageTemplate";
import LandingPage from "MainComponet/Students/LandingPage";
import MainPage from "MainComponet/Confrence/MainPage";
import WhatTellUsu from "MainComponet/Confrence/ConfrenceWhatTellUs";
import WhyConnectarData from "MainComponet/Confrence/whyConnectar";
import MainPageStudent from "MainComponet/Students/StudentMainPage";
import MethodologyConf from "MainComponet/Confrence/Confrenecmethodoloy";
import WhynowConf from "MainComponet/Confrence/ConfrenecWhynow";
import WhytellusConf from "MainComponet/Confrence/ConfrenceWhatTellUs";
import StudentWhyConnectar from "MainComponet/Students/StudentWhyConnectar";
import StudentDataSight from "MainComponet/Students/StudentDataSight";
import Studentmethodology from "MainComponet/Students/Studentmethodolody";
import StudentWhynow from "MainComponet/Students/StudentWhynow";




export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<div>Loading Component.........</div>}>
        <Routes>
          <Route element={<PublicRoutes />}>

            <Route path={"/login"} element={<Basic />} />
            <Route path="/authentication/sign-in/forget" element={<Forget />} />
            <Route path="/authentication/sign-in/reset/:id" element={<ResetPassword />} />
            <Route path="/authentication/sign-in/invite/:id" element={<InviteLink />} />
          </Route>
          <Route path="/" element={<LandingPage />} />

          <Route path="/contact" element={<ContactUs />} />
          <Route path="/privacypolice" element={<PrivacyStatement />} />
          <Route path="/:slug" element={<PageTemplate />} />
          {/* corporate */}
          <Route path={"/corporate/login"} element={<Basic />} />
          <Route path={"/corporate/home"} element={<MainSection />} />
          <Route path="/corporate/methodology" element={<Methodology />} />
          <Route path="/corporate/whyNow" element={<WhyNow />} />
          <Route path="/corporate/why-the-connectar" element={<WhyTheConnectar />} />
          <Route path="/corporate/why-you-should" element={<WhatTellUs />} />
          <Route path="/corporate/privacypolice" element={<PrivacyStatement />} />
          <Route path="/corporate/blogs" element={<BlogContent />} />
          <Route path="/corporate/:slug" element={<PageTemplate />} />
          <Route path="/corporate/contact" element={<ContactUs />} />
{/* student */}
 <Route path={"/student/login"} element={<Basic />} />
          <Route path="/student/home" element={<MainPageStudent />} />
          <Route path="/student/why-the-connectar" element={<StudentWhyConnectar />} />
          <Route path="/student/why-you-should" element={<StudentDataSight />} />
          <Route path="/student/methodology" element={<Studentmethodology />} />
          <Route path="/student/whynow" element={<StudentWhynow />} />
          <Route path="/student/privacypolice" element={<PrivacyStatement />} />
         <Route path="/student/blogs" element={<BlogContent />} />
         <Route path="/student/:slug" element={<PageTemplate />} />
         <Route path="/student/contact" element={<ContactUs />} />
{/* conference & events */}
<Route path={"/conference/login"} element={<Basic />} />

          <Route path="/conference/home" element={<MainPage />} />
          <Route path="/conference/why-the-connectar" element={<WhyConnectarData />} />
          <Route path="/conference/methodology" element={<MethodologyConf />} />
        
          <Route path="/conference/why-you-should" element={< WhytellusConf/>} />
          <Route path="/conference/whynow" element={< WhynowConf/>} />
          <Route path="/conference/privacypolice" element={<PrivacyStatement />} />
          <Route path="/conference/blogs" element={<BlogContent />} />
          <Route path="/conference/:slug" element={<PageTemplate />} />
          <Route path="/conference/contact" element={<ContactUs />} />
          <Route path="*" element={<Navigate to="/" />} />
        
          {/* conference page */}
      

          {/* comapny */}
          <Route element={<PrivateAdminRoute />}>
            <Route path="*" element={<Navigate to="/companies" />} />
            <Route path="/companies" element={<CompaniesTable />} />
            <Route path="/weighting" element={<Categories />} />
            <Route path="/profile" element={<AdminProfile />} />
            <Route path="/snapshot" element={<Snapshot />} />
            <Route path="/feedbacks" element={<FeedbackAlluser />} />
            <Route path="/cms/pages" element={<PageList />} />
            <Route path="/cms/create/page" element={<CreatePage />} />
            <Route path="/pages/edit/:id" element={<EditPage />} />
          </Route>

          <Route element={<PrivateCompanyRoutes />}>
            <Route path="*" element={<Navigate to="/addusers" />} />
            <Route path="/addusers" element={<UsersTable />} />
            <Route path="/uploadusers" element={<UploadUsers />} />
            <Route path="/companyprofile" element={<CompanyProfile />} />
          </Route>

          <Route element={<PrivateUserRoutes />}>
            <Route path="*" element={<Navigate to="/userprofile" />} />
            <Route path="/userprofile" element={<UserProfile />} />
            <Route path="/connections" element={<Connections />} />
            <Route path="/welcomeQuestions" element={<FrontPage />} />

            <Route path="/questionere" element={<Questionere />} />
          </Route>
          <Route path="/feedback/:id" element={<Feedback />} />
          <Route path="/connections/publicprofile/:id" element={<PublicProfile />} />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}
