import {
  Avatar,
  Box,
  Card,
  Chip,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ResponsivePie } from "@nivo/pie";
import Nav from "./Navbar";
import { useParams } from "react-router-dom";
import "./styles.css";
const PublicProfile = () => {
  const { id } = useParams();
  const [user, setUser] = useState();
  const [connection, setConnection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [pieChartData, setPieChartData] = useState(null);

  const userProfile = () => {
    let token = localStorage.getItem("token");
    if (token) {
      axios
        .post(`${process.env.REACT_APP_API_PATH}/validuser`, { token })
        .then((res) => {
          if (res.data.status == 200) {
            setUser(res.data.data);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    userProfile();
    handleConnections();
  }, []);

  const handleConnections = () => {
    const token = localStorage.getItem("token");
    axios
      .post(`${process.env.REACT_APP_API_PATH}/get-connection-details`, { token, connectionId: id })
      .then((res) => {
        if (res.data.status === 200) {
          const connectionsData = res.data.data.connection;

          setConnection(connectionsData);

          const categoryDataAsNumbers = {
            Intelligence: Number(connectionsData.categorySequence.Intelligence || 0),
            Hobbies: Number(connectionsData.categorySequence.Interest || 0),
            Personality: Number(connectionsData.categorySequence.Personality || 0),
            Values: Number(connectionsData.categorySequence.Values || 0),
          };

          const updatedData = Object.assign({}, connectionsData, {
            id: "Compatibility Score",
            subcategories: [
              {
                id: "Personality",
                value: connectionsData.score.personalityScore,
              },
              {
                id: "Values",
                value: connectionsData.score.valuesScore,
              },
              {
                id: "Hobbies",
                value: connectionsData.score.hobbiesScore,
              },
              {
                id: "Intelligence",
                value: connectionsData.score.intelligenceScore,
              },
            ],
          });

          const subcategoryData = updatedData.subcategories.map((subcategory) => {
            const dbValue = connectionsData.score[subcategory.id.toLowerCase() + "Score"] || 0;

            const subcategoryValue = dbValue >= 0 ? dbValue : 100;

            const categoryValue = categoryDataAsNumbers[subcategory.id];

            return {
              id: `${subcategory.id}`,
              label: subcategory.id,
              value: subcategory.value,
              customScore: (subcategoryValue * categoryValue) / 100,
            };
          });

          const totalValue = subcategoryData.reduce((acc, subcategory) => {
            if (subcategory.value !== 0) {
              return acc + subcategory.customScore;
            }

            return acc;
          }, 0);

          const remainingValue = Math.round(100 - totalValue);

          const remainingSlice = {
            id: "Remaining",
            value: remainingValue,
          };

          const subcategoryDataForPie = subcategoryData.map((subcategory) => {
            const subcategoryValue = subcategory.value;
            const subcategoryId = subcategory.id.toLowerCase();
            const latestUserSubcategoryValue = connectionsData.score[subcategoryId + "Score"] || 0;
            const label = getLabel(latestUserSubcategoryValue);
            return {
              id: subcategoryValue == 0 ? "" : `${subcategory.label} (${label})`,
              value: Math.round(subcategory.customScore),
              label: `${subcategory.label}`,
              labelForCustom: label,
            };
          });
          if (remainingValue > 0) {
            subcategoryDataForPie.push(remainingSlice);
          }

          setPieChartData(subcategoryDataForPie);

          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const customColors = ["#FF0000", "#FF6384", "#FFCE56", "#33C7FF", "#D3D3D3"];

  const getLabel = (value) => {
    if (value < 30) return "Less Compatible";
    if (value >= 30 && value <= 69) return "Compatible";
    return "Highly Compatible";
  };

  const isSmScreen = useMediaQuery((theme) => theme.breakpoints.down("xsl"));
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.between("xsl", "sm"));
  const isMedScreen = useMediaQuery((theme) => theme.breakpoints.between("sm", "md"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.between("md", "lg"));

  const chartWidth = isSmScreen
    ? 300
    : isSmallScreen
    ? 320
    : isMediumScreen || isMedScreen
    ? 500
    : 800;
  const chartHeight = isSmScreen
    ? 200
    : isSmallScreen
    ? 220
    : isMediumScreen || isMedScreen
    ? 320
    : 420;


  const renderCustomLabel = (d) => {
    const xValue =
      (isSmallScreen || isSmScreen) ? "-10" :
      (isMediumScreen || isMedScreen) ? "30" : "0";
  
    if (d.id === "Remaining") {
      return (
        <tspan x="0" dy="0em" fontSize={isSmallScreen || isSmScreen ? "9px" : "14px"} >
          {`${d.id}`}
        </tspan>
      );
    }
    if ( d.data.value === 0) {
      return null; 
    }
    const labelArray = [`${d.label}`, `(${d.data.labelForCustom})`];
  
    const intelligenceScore = d.data.value;
    const hobbiesScore = d.data.value;
  
    return labelArray.map((text, index) => (
      <tspan
        key={index}
      
          x={
            (d.id.indexOf("Intelligence") > -1)
              ? (intelligenceScore < 50 ? ((isSmallScreen || isSmScreen) ? "-20" : "-50") : xValue)
              : (d.id.indexOf("Hobbies") > -1)
                ? (hobbiesScore < 50 ? "20" : "5")
                : "-15"
          }
          
        dy={(index === 0) ? (isSmallScreen || isSmScreen ? "1em" : "0.7em") : "1em"}
        fontSize={isSmallScreen || isSmScreen ? "9px" : "14px"}
        textAnchor={
          (d.id.indexOf("Intelligence") > -1 || d.id.indexOf("Hobbies") > -1)
            ? "middle"
            : (d.id.indexOf("Intelligence") > -1 || d.id.indexOf("Hobbies") > -1)
            ? "end"
            : "start"
        }
        
      >
        {text}
      </tspan>
    ));
  };

  return (
    <>
      <Nav data={user} isUserProfile={true} isPublicProfile={true} />
      <Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "10vh",
          }}
        ></Box>
        <Box py={3} sx={{ overflow: "hidden" }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box>
              <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12} mt={5}>
                  <Card>
                    {connection && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: "1rem",
                        }}
                      >
                        {connection.matchedUser && connection.matchedUser.image ? (
                          <>
                            <img
                              src={connection?.matchedUser?.image}
                              alt="..."
                              style={{ height: 100, width: 100, borderRadius: "50px" }}
                            />
                          </>
                        ) : (
                          <Avatar
                            htmlFor="image-upload"
                            label="Image Upload"
                            sx={{
                              height: 150,
                              mb: 2,
                              width: 150,
                              cursor: "pointer",
                            }}
                            style={{ height: 120, width: 120 }}
                          />
                        )}

                        <Box sx={{ display: "flex", flexDirection: "column", marginLeft: "20px" }}>
                          <Typography variant="button" color="text" fontWeight="regular">
                            {connection?.matchedUser?.firstname +
                              " " +
                              (connection.matchedUser?.lastname || " ")}
                          </Typography>
                          <Typography variant="button" color="text" fontWeight="regular">
                            {`${
                              connection.matchedUser?.userRole
                                ? connection.matchedUser?.userRole
                                : ""
                            }`}
                          </Typography>
                          <Typography variant="button" color="text" fontWeight="regular">
                          {`${
                              connection.matchedUser?.contact
                                ? connection.matchedUser?.contact
                                : ""
                            }`}
                          </Typography>
                          <Typography variant="button" color="text" fontWeight="regular">
                          {connection?.matchedUser?.city +
                              " " +
                              (connection.matchedUser?.country || " ")}
                          </Typography>
                          
                        </Box>
                      </Box>
                    )}
                  </Card>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={4} mt={5}>
                  <Card>
                    {connection && (
                      <Box sx={{ padding: "1rem" }}>
                        <Typography variant="h5" component="legend" mb={1}>
                          Restaurants
                        </Typography>
                        <Box>
                          {connection?.matchedUser?.favRestaurant &&
                            connection.matchedUser?.favRestaurant
                              .slice(0, 10)
                              .map((restaurant, index) => (
                                <Chip
                                  key={index}
                                  label={restaurant}
                                  variant="outlined"
                                  style={{
                                    marginRight: "5px",
                                    marginBottom: "5px",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                  }}
                                />
                              ))}
                        </Box>
                      </Box>
                    )}
                  </Card>
                  <Grid item xs={12} md={12} lg={12} mt={2}>
                    <Card>
                      {connection && (
                        <Box sx={{ padding: "1rem" }}>
                          <Typography variant="h5" component="legend" mb={1}>
                            Book
                          </Typography>
                          <Box>
                            {connection?.matchedUser?.favBook &&
                              connection.matchedUser?.favBook.slice(0, 10).map((book, index) => (
                                <Chip
                                  key={index}
                                  label={book}
                                  variant="outlined"
                                  style={{
                                    marginRight: "5px",
                                    marginBottom: "5px",
                                    cursor: "pointer",
                                    fontSize: "16px",
                                  }}
                                />
                              ))}
                          </Box>
                        </Box>
                      )}
                    </Card>
                    <Grid item xs={12} md={12} lg={12} mt={2}>
                      <Card>
                        {connection && (
                          <Box sx={{ padding: "1rem" }}>
                            <Typography variant="h5" component="legend" mb={1}>
                              Movies
                            </Typography>
                            <Box>
                              {connection?.matchedUser?.favMovie &&
                                connection.matchedUser?.favMovie
                                  .slice(0, 10)
                                  .map((movies, index) => (
                                    <Chip
                                      key={index}
                                      label={movies}
                                      variant="outlined"
                                      style={{
                                        marginRight: "5px",
                                        marginBottom: "5px",
                                        cursor: "pointer",
                                        fontSize: "16px",
                                      }}
                                    />
                                  ))}
                            </Box>
                          </Box>
                        )}
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} mt={2}>
                      <Card>
                        {connection && (
                          <Box sx={{ padding: "1rem" }}>
                            <Typography variant="h5" component="legend" mb={1}>
                              Cities
                            </Typography>
                            <Box>
                              {connection?.matchedUser?.favCity &&
                                connection.matchedUser?.favCity
                                  .slice(0, 10)
                                  .map((cities, index) => (
                                    <Chip
                                      key={index}
                                      label={cities}
                                      variant="outlined"
                                      style={{
                                        marginRight: "5px",
                                        marginBottom: "5px",
                                        cursor: "pointer",
                                        fontSize: "16px",
                                      }}
                                    />
                                  ))}
                            </Box>
                          </Box>
                        )}
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12} mt={2}>
                      <Card>
                        {connection && (
                          <Box sx={{ padding: "1rem" }}>
                            <Typography variant="h5" component="legend" mb={1}>
                              Interests and Hobbies
                            </Typography>
                            <Box>
                              {connection?.matchedUser?.hobbies && (
                                <div>
                                  {connection.matchedUser?.hobbies
                                    .slice(0, 10)
                                    .map((hobby, index) => (
                                      <Chip
                                        key={index}
                                        label={hobby}
                                        style={{
                                          marginRight: "5px",
                                          marginBottom: "5px",
                                          cursor: "pointer",
                                          fontSize: "16px",
                                        }}
                                        component="a"
                                        variant="outlined"
                                      />
                                    ))}
                                </div>
                              )}
                            </Box>
                          </Box>
                        )}
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={12} lg={8} mt={5}>
                  <Card>
                    <Box pt={3} px={3} textAlign={isSmallScreen ? "center" : "left"}>
                      <Box mt={0}>
                        <Typography variant="button" color="text" fontWeight="regular">
                          <Typography
                            variant="h5"
                            component="legend"
                            mb={1}
                            fontSize={isSmallScreen ? "18px" : "20px"}
                          >
                            Your Compatibility Score
                          </Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Box pt={3} px={5} textAlign={isSmallScreen ? "center" : "left"}>
                      <Box mt={0}>
                        <Typography variant="button" color="text" fontWeight="regular">
                          <Typography
                            variant="button"
                            color="text"
                            fontWeight="bolder"
                            fontSize="18px"
                            align="center"
                          >
                            Overall Score: {Math.round(connection?.compatibleAvg || "")}
                          </Typography>{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box sx={{ alignItems: "center", display: "flex", flexDirection: "column" }}>
                      <div
                        className="legend-responsive"
                        style={{ width: chartWidth, height: chartHeight }}
                      >
                        {pieChartData && (
                          <ResponsivePie
                            colors={customColors}
                            data={pieChartData}
                            margin={{ top: 40, right: 80, bottom: 80, left: 90 }}
                            innerRadius={0}
                            padAngle={0}
                            cornerRadius={0}
                            activeOuterRadiusOffset={0}
                            borderWidth={0}
                            arcLinkLabelsDiagonalLength={30}
                            arcLinkLabelsTextOffset={0}
                            arcLinkLabelsStraightLength={4}
                            arcLinkLabelsSkipAngle={0}
                            arcLinkLabelsTextColor={{ from: "color", modifiers: [["darker", 0.5]] }}
                            arcLinkLabelsThickness={0}
                            arcLinkLabelsColor={{ from: "color" }}
                            arcLabelsSkipAngle={0}
                            arcLabelsTextColor={{ from: "color", modifiers: [["darker", 0.5]] }}
                            enableArcLinkLabels={true}
                            enableRadialLabels={false}
                            enableSliceLabels={false}
                            enableArcLabels={false}
                            isInteractive={false}
                            arcLinkLabel={renderCustomLabel}
                          />
                        )}
                      </div>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Container>
    </>
  );
};
export default PublicProfile;