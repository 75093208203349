import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

function LandingFooter() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);


  return (
    <footer 
      className={`section-widget ${
         'footer' }`} 
      data-aos="fade-right" 
    >
      <div className="container">
        <div className="row">
          {/* Quick Links Section */}
          <div className="col-md-3">
            <h5>Quick Links</h5>
            <ul className="footerLinks colLinks font">
              <li>
                <Link
                  to={
                    '/corporate/home'
                  }
                >
                 Corporate Connection Platform
                </Link>
              </li>
              <li>
                <Link
                  to={
                    '/conference/home'
                  }
                >
                 Conference & Event Connection Platform
                </Link>
              </li>
              <li>
                <Link
                  to={
                    '/student/home'
                  }
                >
                 Student Connection Platform
                </Link>
              </li>
             
            </ul>
          </div>

          {/* Company Section */}
          <div className="col-md-3">
            <h5>Company</h5>
            <ul className="footerLinks font">
              <li>
                <Link
                  
                  to={'/privacypolice'}
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Get In Touch Section */}
          <div className="col-md-3">
            <h5>Get In Touch</h5>
            <ul className="footerLinks font">
              <li>
                <Link
                  to={
                   '/contact'
                      
                  }
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          {/* Address Section */}
          <div className="col-md-3">
            <h5>Address</h5>
            <ul className="footerLinks font">
              <li>Ground Floor, 470 St Kilda Road, Melbourne, Vic Australia 3004</li>
            </ul>
          </div>
        </div>
        <div className="font bottom">&copy; 2024 The Connectar. All rights reserved.</div>
      </div>
    </footer>
  );
}

export default LandingFooter;
