import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import WYSIWYGEditor from "./WYSIWYGEditor";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Card,
  Grid,
  Modal,
} from "@mui/material";
import MDButton from "components/MDButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";


const style = {
  position: "absolute",
  top: { xs: 400, sm: 400, md: 440, xl: 400 },
  width: 700,
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  pb: 3,
};
const stripHtml = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

const generateSlug = (title) => {
  const words = title.split(" ");
  const slugWords = words.slice(0, 4);  
  return slugWords
    .join("-")
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)/g, "");
};

const CreatePage = () => {
  const apiBaseUrl = process.env.REACT_APP_API_PATH;
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [content, setContent] = useState("");
  const [featuredImage, setFeaturedImage] = useState(null);
  const [status, setStatus] = useState("draft");
  const [publishDate, setPublishDate] = useState("");
  const [categories, setCategories] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState({ save: false, publish: false });
  const [pages, setPages] = useState("");
  const [error, setError] = useState(null);
  const [summary, setSummary] = useState("");
  const [hightitle, setHightitle] = useState("");
  const fetchPages = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/pages/getPages`);
      setPages(response.data);
    } catch (err) {
      setError("Failed to fetch pages");
    }
  };
  useEffect(() => {
    fetchPages();
  }, [apiBaseUrl]);
  const checkIfTitleExists = (title) => {
    return pages.some(page => page.title.toLowerCase() === title.toLowerCase());
  };
  const navigate = useNavigate();
  const handleSubmit = async (e,newStatus) => {
    e.preventDefault();
    if (checkIfTitleExists(title)) {
      Swal.fire({
        icon: "warning",
        title: "Title Already Exists",
        text: "A page with this title already exists. Please update the existing page or choose a different title.",
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const existingPage = pages.find(page => page.title.toLowerCase() === title.toLowerCase());
          navigate(`/cms/create/page`);
        }
      });
      return; 
    }
    setIsButtonDisabled((prevState) => ({
      ...prevState,
      [newStatus === "published" ? "publish" : "save"]: true,
    }));
    const formData = new FormData();
    formData.append("title", title);
    formData.append("summary", summary);
    formData.append("hightitle", hightitle);
    formData.append("slug", slug);
    formData.append("content", content);
    formData.append("featuredImage", featuredImage);
    formData.append("status", newStatus || status);
    formData.append("publishDate", status === "published" ? new Date().toISOString() : "");
    formData.append("categories", categories);

    try {
      await axios.post(`${apiBaseUrl}/admin/pages`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      Swal.fire({
        icon: "success",
        title: newStatus === "published" ? "Page Published!" : "Draft Saved!",
        text: newStatus === "published"
          ? "Your page has been published successfully."
          : "The draft has been saved successfully.",
      });
      navigate("/cms/pages");
      closeModal();
    
    } catch (err) {
      console.error("Failed to create page:", err);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to create the page. Please try again.",
      });
    }finally {
      setIsButtonDisabled((prevState) => ({
        ...prevState,
        [newStatus === "published" ? "publish" : "save"]: false,
      }));
    }
  };

  const handleFileChange = (e) => {
    setFeaturedImage(e.target.files[0]);
  };
 
  useEffect(() => {
    const sanitizedTitle = stripHtml(title);
    setSlug(generateSlug(sanitizedTitle));;
  }, [title]);
  const closeModal = () => {
    setTitle("");
    setSummary("");
    setHightitle("");
    setSlug("");
    setContent("");
    setFeaturedImage(null);
    setStatus("draft");
    setPublishDate("");
    setCategories("");
  };
  const isFormFilled = title.trim() ;
  const handleBack = (e) => {
    e.preventDefault(); 
    navigate(-1);
  };
  
  return (
<>
              <DashboardLayout>
          <Sidenav brandName="Connectar" routes={routes} />
      <MDBox  pb={3}>
     
        <Grid container spacing={6}>
          <Grid item xs={12} lg={12}>
        <Card sx={{ ...style,
            width: {
              xs: "90%",
              sm: "80%",
              md: "500px",
              lg: "60%",
              xl: "90%",
            },
          }}>
         <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        sx={{
          backgroundColor: "#1e90ff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
        Create New Page
        </MDTypography>
        <MDButton
                 style={{
                  color: "black",
                  border: "1px solid white",
                  background: "white",
                  marginRight: "10px",
                }} 
                   onClick={handleBack}
                  >
                <ArrowBackIcon style={{ marginRight: "8px" }} />   Back
                </MDButton>
      </MDBox>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2} px={4} py={2}>
              <Grid item xs={6} mt={2}
               >
                <TextField
                  label="Page Title"
                  variant="outlined"
                  fullWidth
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
                {/* <label for="Page Title" style={{fontSize:"14px", color:"#7b809a"}}>Page Title *</label>
                <div style={{
                      borderRadius: "8px",
                      position: "relative",
                      border: "1px solid rgb(224 233 242)",
                      zIndex: "999",
                      height: "auto", 
                    }}>
                      
                 <ReactQuill
                        theme="snow"
                        label="Page Title"
                        variant="outlined"
                        fullWidth
                        value={title}
                      
                        onChange={(content) => setTitle(content)}
                        required
                        modules={{
                          toolbar: [
                            [{ color: ["#FF0000", "#00FF00", "#0000FF", "#000000", "#FFFFFF", "color-picker"] }],
                          ],
                        }}
                      />

                    </div> */}
              </Grid>
              <Grid item xs={6} mt={2}>
              <TextField
                  label="Page Highlight Title"
                  variant="outlined"
                  fullWidth
                  value={hightitle}
                  onChange={(e) => setHightitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
              <TextField
                  label="Page Summary"
                  variant="outlined"
                  fullWidth
                  value={summary}
                  multiline
                  rows={4}        
                  onChange={(e) => setSummary(e.target.value)}
                  required
                />
              </Grid>
             
              <Grid item xs={12}>
                <WYSIWYGEditor value={content} onChange={setContent} />
              </Grid>
              <Grid item xs={12}>
              <FormControl fullWidth>
                    <Button
                      component="label"
                      variant="outlined"
                      startIcon={<CloudUploadIcon style={{ color: "#7b809a" }} />}
                      sx={{ marginRight: "1rem" , "&:hover": {
                        borderColor: "#0d6efd",
                      }, }}
                    >
                      <span style={{ color: "#7b809a" }}> Upload Featured Image</span>
                      <input
                        type="file"
                        accept="application/pdf,.pdf, .jpg, .jpeg, .png, .doc, .docx, .txt"
                        hidden
                        onChange={handleFileChange}
                      />
                    </Button>
                    
                    {featuredImage && (
                    <Typography sx={{ marginTop: "8px", fontSize:"10px" }}>
                      {typeof featuredImage === "object"
                        ? `Selected File: ${featuredImage.name}`
                        : `Current Image: ${featuredImage}`}
                    </Typography>
                  )}
                  </FormControl>
              </Grid>
              <Grid item xs={12}>
             
               <FormControl
  fullWidth
  margin="normal"
  sx={{ fontSize: "14px", '& .MuiInputBase-root': { fontSize: "14px", padding: "12px" } }}
>
  <InputLabel>Categories</InputLabel>
  <Select
    value={categories}
    onChange={(e) => setCategories(e.target.value)}
    label="Categories"
    sx={{ fontSize: "14px", padding: "12px" }}
  >
    <MenuItem value="corporate">Corporate</MenuItem>
    <MenuItem value="Student">Student</MenuItem>
    <MenuItem value="conference">Conference & Event</MenuItem>
  </Select>
</FormControl>
              </Grid>
              
              <Grid item xs={6}>
                      <MDButton
                        color="info"
                        fullWidth
                        onClick={(e) => handleSubmit(e, "published")}
                        disabled={!isFormFilled || isButtonDisabled.publish}
                      >
                        {isButtonDisabled.publish ? "Publishing..." : "Publish"}
                      </MDButton>
                    </Grid>
                    <Grid item xs={6}>
                      <MDButton
                        color="info"
                        type="submit"
                        fullWidth
                        disabled={!isFormFilled || isButtonDisabled.save}
                      >
                        {isButtonDisabled.save ? "Saving Draft..." : "Save Draft"}
                      </MDButton>
                    </Grid>
              
            </Grid>
          </form>
      </Card>
      </Grid>
        </Grid>
      </MDBox>
      </DashboardLayout>
      <ToastContainer limit={1} className="custom-toast"/>
</>
  );
};

export default CreatePage;