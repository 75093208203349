import React, { useEffect } from "react";
import Academic from "../../assets/images/Studentpage/Academic.svg";
import Engagement from "../../assets/images/Studentpage/Engagement.svg";
import Health from "../../assets/images/Studentpage/Health.svg";
import Retention from "../../assets/images/Studentpage/Retention.svg";
import Creativity from "../../assets/images/Studentpage/Creativity.svg";
import Reduced from "../../assets/images/Studentpage/studentmain.svg";
import Stress from "../../assets/images/Studentpage/Stress.svg";
import Organization from "../../assets/images/Studentpage/Organization.svg";
import References from "../../assets/images/Studentpage/References.svg";
// import Header from "./Header";
import Header from "../../MainComponet/Header";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Studentevent.css";
const StudentDataSight = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);
  return (
    <>
      <Header />
      <section class="border1">
        <section class="container">
          <section class="row align-items-center">
            <section class="col-sm-12 bannerContent" data-aos="fade-right">
              <h2>
                {" "}
                What Does The <span className="heading-color ">Data Tell Us? </span>
              </h2>
              <p className="font text-left">
                Here is some data that supports the benefits of improved connection for students at
                universities and colleges, along with references to the studies and organizations
                that published these findings:
              </p>
            </section>
          </section>
        </section>
      </section>

      <section class="content-widget">
        <section class="container">
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={Academic} alt="" style={{ height: "350px" }} />
            </section>
            <section class="col-md-7">
              <h2>
                1. Improved <span className="heading-color ">Academic Performance:</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>National Survey of Student Engagement (NSSE):</b> Students who participate in
                    extracurricular activities and feel socially connected to their peers tend to
                    perform better academically. The NSSE report found that students who engaged in
                    active learning and had strong social connections were more likely to have
                    higher GPAs and greater academic satisfaction.
                    <ul>
                      <li>
                        <b>
                          Source : National Survey of Student Engagement (NSSE), Engagement
                          Insights:
                        </b>{" "}
                        Survey Findings on the Quality of Undergraduate Education," 2019.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>American College Health Association (ACHA):</b> Research shows that students
                    who have strong social support networks experience less academic burnout and are
                    more likely to succeed academically.
                    <ul>
                      <li>
                        <b>Source : American College Health Association:</b> "National College
                        Health Assessment," 2020.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={Engagement} alt="" style={{ height: "350px" }} />
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                2. Enhanced <span className="heading-color ">Student Engagement:</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>Higher Education Research Institute (HERI):</b> A survey conducted by HERI
                    revealed that students who feel a sense of belonging and connection to their
                    university are more likely to engage in extracurricular activities, volunteer
                    work, and campus events, which are all correlated with higher satisfaction and
                    academic success.
                    <ul>
                      <li>
                        <b>Source: Higher Education Research Institute, "The American Freshman:</b>{" "}
                        National Norms," 2018.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>National Association of Student Personnel Administrators (NASPA):</b> Students
                    who report feeling more engaged with campus life are more likely to stay
                    enrolled and complete their degree programs. The NASPA study emphasized the
                    importance of social connection in fostering academic success and overall
                    university satisfaction.
                    <ul>
                      <li>
                        <b>
                          Source: National Association of Student Personnel Administrators
                          (NASPA)"Student Engagement and Retention:
                        </b>{" "}
                        Evidence-Based Practices," 2016.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={Health} alt="" style={{ height: "350px" }} />
            </section>
            <section class="col-md-7">
              <h2>
                3. Improved <span className="heading-color "> Mental Health and Well-Being :</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> American College Health Association (ACHA):</b> The ACHA’s National College
                    Health Assessment found that students with a strong social network have lower
                    rates of anxiety, depression, and other mental health challenges. Those who
                    report feeling more connected to their peers are less likely to experience
                    mental health crises during their time at university.
                    <ul>
                      <li>
                        <b>Source: American College Health Association,</b> "National College Health
                        Assessment," 2020.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>University of California, Berkeley:</b> esearch conducted by UC Berkeley
                    found that students who feel a sense of belonging and connection on campus have
                    better mental health outcomes, including reduced levels of anxiety and
                    depression.
                    <ul>
                      <li>
                        <b>Source: University of California, Berkeley,</b> "The Importance of
                        Belonging for Mental Health in College Students," 2019.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={Retention} alt="" style={{ height: "350px" }} />
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                4. Increased <span className="heading-color "> Retention Rates:</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>National Center for Education Statistics (NCES):</b> NCES data shows that
                    universities with programs designed to foster student connection and engagement
                    have higher retention rates. When students feel connected to their campus
                    community, they are more likely to stay enrolled and complete their studies.
                    <ul>
                      <li>
                        <b>Source:National Center for Education Statistics (NCES),</b> "The
                        Condition of Education," 2019.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b>Gallup-Purdue Index</b> Gallup’s study found that students who build close
                    friendships on campus are significantly more likely to graduate. The study
                    highlights that a sense of connection with the university, faculty, and peers
                    directly impacts student persistence and graduation rates
                    <ul>
                      <li>
                        <b>Source: Gallup-Purdue Index,</b> "The Relationship Between College
                        Engagement and Post-Graduation Outcomes," 2014.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={Creativity} alt="" style={{ height: "350px" }} />
            </section>
            <section class="col-md-7">
              <h2>
                5.Greater Innovation <span className="heading-color "> and Creativity:</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Stanford University:</b> Research at Stanford shows that students who engage
                    with peers from diverse backgrounds and disciplines are more likely to develop
                    creative solutions to problems. Collaboration fosters innovation, particularly
                    when students bring different perspectives and knowledge to the table.
                    <ul>
                      <li>
                        <b>Source: Stanford University, "Creativity and Collaboration,</b> "The
                        Impact of Interdisciplinary Engagement," 2017.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b> Harvard Business Review:</b> A study by Harvard found that when students
                    engage with peers outside their immediate academic circles, they are more likely
                    to contribute to innovative ideas. These cross-disciplinary interactions lead to
                    more original and effective solutions to problems
                    <ul>
                      <li>
                        <b>Source: Harvard Business Review, "Creativity and Collaboration,</b> "The
                        Impact of Employee Engagement on Performance," 2016.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={Reduced} alt="" style={{ height: "350px" }} />
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                6. Higher <span className="heading-color ">Student Satisfaction:</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> National Survey of Student Engagement (NSSE):</b> NSSE’s findings show that
                    students who report having strong connections to their peers and faculty are
                    significantly more satisfied with their overall university experience. The sense
                    of community directly correlates with a positive college experience.
                    <ul>
                      <li>
                        <b>
                          Source:National Survey of Student Engagement (NSSE), "Engagement Insights
                          ,
                        </b>{" "}
                        Survey Findings on the Quality of Undergraduate Education," 2019.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b> Gallup:</b> Gallup’s research suggests that students who form close
                    friendships at college are more likely to feel fulfilled in their personal lives
                    and have higher levels of life satisfaction post-graduation.
                    <ul>
                      <li>
                        <b>Source:Gallup, "Engagement Insights ,</b>"The Gallup-Purdue Index," 2014.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={Stress} alt="" style={{ height: "350px" }} />
            </section>
            <section class="col-md-7">
              <h2>
                7.Reduced
                <span className="heading-color "> Stress and Burnout:</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Journal of College Student Development:</b> study published in the Journal
                    of College Student Development found that students who are more socially
                    integrated into their campus community experience lower levels of stress and
                    burnout.
                    <ul>
                      <li>
                        <b>Source:ournal of College Student Development ,</b> "Social Integration
                        and Stress in College Students," 2016.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b> The American Institute of Stress:</b> According to the American Institute of
                    Stress, students who experience isolation and lack of social support are more
                    prone to mental health challenges, including stress and burnout. Fostering
                    connections is critical for mitigating these risks.
                    <ul>
                      <li>
                        <b>Source: The American Institute of Stress,</b> "Workplace Stress," 2019.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={Organization} alt="" style={{ height: "350px" }} />
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                8. Improved <span className="heading-color "> Mental Health Outcomes:</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>World Health Organization (WHO):</b> According to WHO, depression and anxiety
                    cost the global economy billions in lost productivity. In the university
                    context, students who feel disconnected from their peers or the institution are
                    more prone to mental health issues. Improved student connection helps reduce
                    these risks and promotes a healthier campus environment
                    <ul>
                      <li>
                        <b>Source: World Health Organization (WHO) ,</b> "Depression and Other
                        Common Mental Disorders: Global Health Estimates," 2017.
                      </li>
                    </ul>
                  </li>
                  <li>
                    <b> Journal of Business and Psychology:</b> Research found that students who
                    feel a sense of belonging at their university report better mental health
                    outcomes, including lower levels of anxiety and higher levels of happiness.
                    <ul>
                      <li>
                        <b>Source: Journal of Business and Psychology ,</b> "The Importance of
                        Belongingness in the Workplace," 2013.
                      </li>
                    </ul>
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={References} alt="" style={{ height: "350px" }} />
            </section>
            <section class="col-md-7">
              <h2>References:</h2>
              <ul className="font">
                <li>
                  National Survey of Student Engagement (NSSE), "Engagement Insights: Survey
                  Findings on the Quality of Undergraduate Education," 2019.
                </li>
                <li>
                  {" "}
                  American College Health Association, "National College Health Assessment," 2020.
                </li>
                <li>
                  {" "}
                  Higher Education Research Institute, "The American Freshman: National Norms,"
                  2018.
                </li>
                <li>
                  National Association of Student Personnel Administrators (NASPA), "Student
                  Engagement and Retention: Evidence-Based Practices," 2016.
                </li>
                <li>
                  {" "}
                  Gallup-Purdue Index, "The Relationship Between College Engagement and
                  Post-Graduation Outcomes," 2014.
                </li>
                <li>
                  University of California, Berkeley, "The Importance of Belonging for Mental Health
                  in College Students," 2019.
                </li>
                <li>
                  Stanford University, "Creativity and Collaboration: The Impact of
                  Interdisciplinary Engagement," 2017.
                </li>
                <li>
                  Harvard Business Review, "The Impact of Employee Engagement on Performance," 2016.
                </li>
                <li>
                  Journal of Occupational Health Psychology. "Social Support at Work and in the
                  Family: Dual-Pathway Associations with Sleep Quality and Sleep Duration," 2017.
                </li>
                <li>
                  Journal of College Student Development, "Social Integration and Stress in College
                  Students," 2016.
                </li>
                <li>
                  {" "}
                  World Health Organization, "Depression and Other Common Mental Disorders: Global
                  Health Estimates," 2017
                </li>
                <li>
                  Journal of Business and Psychology, "The Importance of Belongingness in the
                  Workplace," 2013.
                </li>
              </ul>
            </section>
          </section>
        </section>
      </section>

      <section class="section-widget">
        <section class="container">
          <section class="getInTouch text-center mt-5" data-aos="fade-right">
            <p className="font">
              We hope these statistics help, let us know if there are any others we might have
              misses that you'd like included.
            </p>
            <a href="/student/contact" class="btn-Pink">
              Get in touch
            </a>
          </section>
        </section>
      </section>

      <div data-aos="fade-right" id="footer">
        {" "}
        <Footer />{" "}
      </div>
    </>
  );
};

export default StudentDataSight;
