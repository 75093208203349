import React, { useEffect, useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import university from "../../assets/images/Studentpage/Learning.svg";
import gathers from "../../assets/images/Studentpage/gathers.svg";
import friendships from "../../assets/images/Studentpage/friendships.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import "MainComponet/css/custom.css";

import howItWork from "../../assets/images/Video/TheConnectarFinal.mp4";
import videoThumbnail from "../../assets/images/Video/thumbnail.png";
import GetTounch from "./GetTounch";

const Studentmethodolody = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);

  const togglePlayPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
    const video = videoRef.current;
    if (video) {
      video.load();
    }
  }, []);
  return (
    <>
      <Header />
      <section class="border1">
        <section className="container">
          <section className="row align-items-center" data-aos="fade-right">
            <section class="col-sm-12 bannerContent" data-aos="fade-right">
              <h2>
                <span className="heading-color"> The Connectar </span> Methodology
              </h2>
              <p class="text-left font">
                At The Connectar, we are focused on bringing all students together, helping them
                thrive academically, socially, and emotionally. By fostering a culture of meaningful
                connections, we enable students to collaborate on projects, engage in academic
                discussions, and build lasting friendships that enrich their overall university
                experience.
              </p>
            </section>
            {/* <section class="col-sm-6 video-container">
              <div
                class="video-wrapper"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <video
                  ref={videoRef}
                  poster={videoThumbnail}
                  class="videoframe"
                  preload="auto"
                  loop
                  allow="autoplay; fullscreen;"
                  allowfullscreen
                >
                  <source src={howItWork} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <div className="play-pause-btn" onClick={togglePlayPause}>
                  {isPlaying ? (
                    <></>
                  ) : (
                    <i className="fa fa-play-circle-o video_icon" aria-hidden="true"></i>
                  )}
                  {isHovered && isPlaying && (
                    <div className="pause-icon">
                      <i className="fa fa-pause-circle-o video_icon1" aria-hidden="true"></i>
                    </div>
                  )}
                </div>
              </div>
            </section> */}
          </section>
        </section>
      </section>
      <section class="container">
        <section class="container">
          <section class="row align-items-center" data-aos="fade-right">
            <section class="col-sm-6">
              <img
                src={university}
                alt=""
                style={{ height: "350px", width: "auto" }}
                className="img-fluid"
              />
            </section>
            <section class="col-sm-6 bannerContent">
              <h2>
                {" "}
                How do <span className="heading-color">we do this? </span>
              </h2>
              <p class="text-left font">
                Over the years, we’ve developed our proprietary Pollen Pairing algorithm, designed
                to connect students based on what makes them unique — their academic interests,
                extracurricular passions, personality and values. We understand that university life
                can be overwhelming, and students often struggle to find the right people to connect
                with. The Connectar ensures that these connections are meaningful and beneficial,
                fostering an environment of support, learning, and personal growth.
              </p>
            </section>
          </section>
        </section>
      </section>

      <section class="container">
        <section class="row align-items-center" data-aos="fade-right">
          <section class="col-sm-6 bannerContent">
            <p class="text-left font">
              Our process begins with a simple questionnaire that gathers information about each
              student’s extracurricular activities, personality, values and intelligence. This data
              is securely processed using our Pollen Pairing algorithm to identify students who
              share common interests and can help each other succeed. Once we’ve made the
              connection, we introduce students via email or a calendar invite, encouraging them to
              meet either virtually or in person (whenever possible) to discuss academics, social
              activities, or simply get to know each other.
            </p>
          </section>
          <section class="col-sm-6">
            <img
              src={gathers}
              alt=""
              style={{ height: "350px", width: "400px" }}
              className="img-fluid"
            />
          </section>
        </section>
      </section>

      <section class="container">
        <section class="row align-items-center" data-aos="fade-right">
          <section class="col-sm-6">
            <img src={friendships} alt="" style={{ height: "300px" }} className="img-fluid" />
          </section>
          <section class="col-sm-6 bannerContent">
            <p class="text-left font">
              These initial connections often lead to collaborations on assignments, study groups,
              and friendships that enhance the student experience. By connecting students across
              departments, years, and interests, The Connectar ensures that students engage in
              meaningful, cross-disciplinary relationships, leading to improved academic success,
              personal well-being, and a greater sense of belonging.
            </p>
          </section>
        </section>
      </section>

      <GetTounch />
      <div data-aos="fade-right" id="footer">
        {" "}
        <Footer />{" "}
      </div>
    </>
  );
};

export default Studentmethodolody;
