import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Footer() {
  const location = useLocation();

  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);

  const isCorporateRoute = location.pathname.startsWith('/corporate');
  const isConferenceRoute = location.pathname.startsWith('/conference');
  const isStudentRoute = location.pathname.startsWith('/student');

  let privacyPolicyLink = '/privacypolice';
  if (isCorporateRoute) {
    privacyPolicyLink = '/corporate/privacypolice';
  } else if (isConferenceRoute) {
    privacyPolicyLink = '/conference/privacypolice';
  } else if (isStudentRoute) {
    privacyPolicyLink = '/student/privacypolice';
  }

  return (
    <footer 
      className={`section-widget ${
        isStudentRoute ? 'footer-student' : 
        isConferenceRoute ? 'footer-con' : 
        'footer-corp'}`} 
      data-aos="fade-right"
    >
      <div className="container">
        <div className="row">
          {/* Quick Links Section */}
          <div className="col-md-3">
            <h5>Quick Links</h5>
            <ul className="footerLinks colLinks font">
              <li>
                <Link
                  to={
                    isCorporateRoute
                      ? '/corporate/why-the-connectar'
                      : isConferenceRoute
                      ? '/conference/why-the-connectar'
                      : '/student/why-the-connectar'
                  }
                >
                  Why The Connectar
                </Link>
              </li>
              <li>
                <Link
                  to={
                    isCorporateRoute
                      ? '/corporate/why-you-should'
                      : isConferenceRoute
                      ? '/conference/why-you-should'
                      : '/student/why-you-should'
                  }
                >
                  Data Insights
                </Link>
              </li>
              <li>
                <Link
                  to={
                    isCorporateRoute
                      ? '/corporate/methodology'
                      : isConferenceRoute
                      ? '/conference/methodology'
                      : '/student/methodology'
                  }
                >
                  Our Methodology
                </Link>
              </li>
              <li>
                <Link
                  to={
                    isCorporateRoute
                      ? '/corporate/whynow'
                      : isConferenceRoute
                      ? '/conference/whynow'
                      : '/student/whynow'
                  }
                >
                  Why Act Now
                </Link>
              </li>
            </ul>
          </div>

          {/* Company Section */}
          <div className="col-md-3">
            <h5>Company</h5>
            <ul className="footerLinks font">
              <li>
                <Link
                  className={
                    location.pathname === privacyPolicyLink
                      ? 'nav-link active'
                      : ''
                  }
                  to={privacyPolicyLink}
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>

          {/* Get In Touch Section */}
          <div className="col-md-3">
            <h5>Get In Touch</h5>
            <ul className="footerLinks font">
              <li>
              <Link
                  to={
                    isCorporateRoute
                      ? '/corporate/contact'
                      : isConferenceRoute
                      ? '/conference/contact'
                      : '/student/contact'
                  }
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          {/* Address Section */}
          <div className="col-md-3">
            <h5>Address</h5>
            <ul className="footerLinks font">
              <li>Ground Floor, 470 St Kilda Road, Melbourne, Vic Australia 3004</li>
            </ul>
          </div>
        </div>
        <div className="font bottom">&copy; 2024 The Connectar. All rights reserved.</div>
      </div>
    </footer>
  );
}

export default Footer;
