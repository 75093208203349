import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "./event.css";
import Remote from "../../assets/images/confrence/Hybrid.svg";
import conferences from "../../assets/images/confrence/conferences.svg";
import competitive from "../../assets/images/confrence/competitive.svg";
import LongTerm from "../../assets/images/confrence/LongTerm.svg";
import EmployeeHealth from "../../assets/images/confrence/connections.svg";
import GetConfrenecTounch from "./GetInTouch";
const ConfrenecWhynow = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);
  return (
    <>
      <Header />

      {/* Tell us section */}
      <section class="border1">
        <section class="container">
          <section class="row align-items-center">
            <section class="col-sm-12 bannerContent" data-aos="fade-right">
              <h2>
                Why you should <span className="heading-col">do something now </span>
              </h2>
              <section class="col-sm-12">
                <p class="text-left font">
                  As the world adapts to new ways of working and collaborating, the need for
                  meaningful connections at conferences and events has never been more crucial. With
                  the rise of hybrid and virtual events, while technology offers convenience, it can
                  also lead to a sense of disconnection. Attendees may feel isolated, missing out on
                  the full experience of networking and engaging with peers. However, those who
                  embrace connection-building strategies can transform this challenge into an
                  opportunity to enhance event value and participant satisfaction.
                </p>
                <div className="col-md-12 order-md-1">
                  <p className="font text-left">
                    If your conference or event is looking to improve attendee engagement, foster
                    lasting relationships, and ensure that every participant walks away with
                    valuable connections, The Connectar is the perfect solution.
                  </p>
                  {/* <div className="col-md-12 order-md-1">
                    <p className="font text-left">
                   
                    </p>
                  </div> */}
                </div>
              </section>
            </section>
          </section>
        </section>
      </section>
      {/* baner text section */}

      <section className="container">
        <section className="content-widget">
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center">
              <img src={Remote} alt="" style={{ height: "300px" }} className="img-fluid" />
            </div>
            <div className="col-md-7">
              <h2>
                Impact of <span className="heading-col"> Hybrid and Virtual Events</span>
              </h2>
              <p className="font text-left">
                As events become increasingly virtual or hybrid, ensuring that participants feel
                connected can be a challenge. Remote attendees can often feel disconnected, leading
                to reduced engagement, less interaction, and diminished event satisfaction.
                Addressing these issues promptly is essential for keeping participants engaged and
                maximizing the overall success of your event.
              </p>
            </div>
          </div>
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center order-md-2">
              <img src={conferences} alt="" width="400px" className="img-fluid" />
            </div>
            <div className="col-md-7 order-md-1">
              <h2>
                Changing Expectations <span className="heading-col">of Event Attendees</span>
              </h2>
              <p className="font text-left">
                Today’s conference attendees expect more than just a series of lectures or
                presentations. They want opportunities to network, share ideas, and engage in
                meaningful conversations. By focusing on creating connections, event organizers can
                meet these expectations, providing a more dynamic and engaging event that attracts
                attendees year after year. Failing to prioritize these connections may result in
                lower attendee satisfaction and engagement.
              </p>
            </div>
          </div>
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center">
              <img src={competitive} alt="" style={{ height: "300px" }} className="img-fluid" />
            </div>
            <div className="col-md-7">
              <h2>
                <span className="heading-col">Competitive </span> Advantage in the Event Space
              </h2>
              <p className="font text-left">
                {" "}
                In today’s highly competitive event market, offering an environment where attendees
                can form meaningful connections is a significant differentiator. Events that foster
                strong networking and collaboration are more likely to stand out and build loyalty
                among attendees. Delaying action on improving attendee connection could result in
                your event being overshadowed by competitors who are already prioritizing networking
                and engagement.
              </p>{" "}
            </div>
          </div>
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center order-md-2">
              <img src={LongTerm} alt="" style={{ height: "350px" }} className="img-fluid" />
            </div>
            <div className="col-md-7 order-md-1">
              <h2>
                Long-Term{" "}
                <span className="heading-col">Impact on Attendee Satisfaction and Retention</span>
              </h2>
              <p className="font text-left">
                Investing in fostering connections at your events is not just about addressing
                immediate needs; it’s about creating a foundation for long-term success. Events that
                focus on helping attendees connect in meaningful ways often see higher retention
                rates, increased word-of-mouth promotion, and a loyal attendee base. Attendees who
                have valuable experiences and form lasting relationships are more likely to return
                for future events.
              </p>
            </div>
          </div>
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center">
              <img src={EmployeeHealth} alt="" style={{ height: "300px" }} className="img-fluid" />
            </div>
            <div className="col-md-7">
              <h2>
                <span className="heading-col">Enhanced Event </span> Experience and Networking
                Opportunities
              </h2>
              <p className="font text-left">
                {" "}
                Attendees are increasingly seeking events that offer value beyond just content—they
                want networking opportunities that lead to professional growth and personal
                connections. By implementing connection-building strategies, you provide attendees
                with not only an enriching event experience but also opportunities for career
                advancement, mentorship, and collaboration that extend well beyond the event itself.
                <br />
                By acting now to prioritize meaningful connections, you ensure that your event
                stands out, fosters deeper engagement, and delivers an experience that attendees
                will remember and share with others. The Connectar is here to help you build a
                connected, engaged, and loyal event community that will drive the success of your
                future events.
              </p>{" "}
            </div>
          </div>
        </section>
      </section>

      <GetConfrenecTounch />

      <div data-aos="fade-right" id="footer">
        {" "}
        <Footer />{" "}
      </div>
    </>
  );
};

export default ConfrenecWhynow;
