import Footer from "./Footer";
import GetInTouch from "./GetInTouch";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "./Header";
import connectstaff from "../../assets/images/confrence/coll.svg";
import Improved from "../../assets/images/confrence/Improved.svg";
import referencability from "../../assets/images/confrence/referencability.svg";
import Communication from "../../assets/images/confrence/Communication.svg";
import Career from "../../assets/images/confrence/Advancement.svg";
import Conflict from "../../assets/images/confrence/compress.svg";
import Invovation from "../../assets/images/confrence/invoation.svg";
import CompanyEvnet1 from "../../assets/images/confrence/evnt.svg";
import CompanyEvnet2 from "../../assets/images/confrence/evnt1.svg";
import companyevnet from "../../assets/images/confrence/companyevent.svg";
import GetConfrenecTounch from "./GetInTouch";
import "./event.css";
const WhyConnectarData = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);

  return (
    <>
      <Header />
      <section class="border1">
        <section className="container">
          <section className="row align-items-center">
            <section className="col-sm-12 bannerContent" data-aos="fade-right">
              <h2>
                {" "}
                Why <span className="heading-col"> The Connectar? </span>
              </h2>
              <p className="text-left font">
                Improved connection among event attendees offers a wide range of benefits,
                contributing not only to event success but to the long-term professional development
                of participants. Here are several reasons why connection is key to enhancing any
                event:
              </p>
            </section>
          </section>
        </section>
      </section>
      <section className="content-widget">
        <section className="container">
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img src={connectstaff} alt="" style={{ height: "300px" }} className="img-fluid" />
            </section>
            <section className="col-md-7">
              <h2>
                1. Enhanced Professional <span className="heading-col">Collaboration</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>Networking Opportunities:</b> Attendees can easily connect with industry
                    peers, potential collaborators, and mentors, fostering future business
                    opportunities.
                  </li>
                  <li>
                    <b>Panel Discussions and Breakout Sessions:</b> Strategic connections facilitate
                    participation in meaningful discussions and workshops, enhancing learning and
                    problem-solving.
                  </li>
                  <li>
                    <b>Peer Learning:</b> Participants can share experiences, knowledge, and
                    insights, which drives innovation and fresh perspectives.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img src={Improved} alt="" style={{ height: "300px" }} className="img-fluid" />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>
                2.Improved Well-being and<span className="heading-col"> Event Satisfaction</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>Social Interaction:</b> Strong connections help reduce feelings of isolation,
                    especially for first-time attendees or those attending virtual events.
                  </li>
                  <li>
                    <b>Supportive Networks:</b> Attendees can form valuable social and professional
                    connections that provide support both during and after the event.
                  </li>
                  <li>
                    <b>Stress Reduction:</b> Meaningful connections help mitigate stress by providing
                    attendees with social interactions and collaborative opportunities.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img src={referencability} alt="" style={{ height: "300px" }} className="img-fluid" />
            </section>
            <section className="col-md-7">
              <h2>
                3. Increased Engagement <span className="heading-col">and Retention </span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Sense of Belonging:</b> Attendees who feel connected to the event are more
                    likely to stay engaged, participate actively, and return for future events.
                  </li>
                  <li>
                    <b>Active Participation: </b> Meaningful connections encourage higher
                    participation in sessions, workshops, and discussions, ensuring a more impactful
                    event.
                  </li>
                  <li>
                    <b>Better Experience:</b> Connecting with like-minded individuals enhances the
                    overall event experience and boosts attendee satisfaction.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2 mb-3">
              <img src={Communication} alt="" style={{ height: "300px" }} className="img-fluid" />
            </section>
            <section className="col-md-7 order-md-1 ">
              <h2>
                4. Boosted Social and <span className="heading-col">Communication Skills</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>Enhanced Networking:</b> Attendees improve their ability to network,
                    communicate effectively, and collaborate with peers, which benefits both the
                    event and their professional development.
                  </li>
                  <li>
                    <b>Conflict Resolution:</b> With stronger connections, attendees can navigate
                    disagreements or differing opinions more constructively during collaborative
                    activities.
                  </li>
                  <li>
                    <b>Leadership Opportunities:</b> Event-based connections open opportunities for
                    attendees to take on leadership roles or to mentor others.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center mt-4 mb-4" data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img src={Career} alt="" style={{ height: "300px" }} className="img-fluid" />
            </section>
            <section className="col-md-7">
              <h2>
                5. Career <span className="heading-col">Advancement</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Career Development:</b> Attendees can connect with industry professionals,
                    recruiters, and thought leaders, helping them expand their professional
                    networks.
                  </li>
                  <li>
                    <b>Job Opportunities:</b> Networking at events can lead to career opportunities,
                    from job offers to internships and consulting projects.
                  </li>
                  <li>
                    <b>Mentorship:</b> Conferences and events create opportunities for mentorship,
                    where seasoned professionals can offer valuable career guidance.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img src={Conflict} alt="" style={{ height: "300px" }} className="img-fluid" />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>
                6. Cultural Exchange <span className="heading-col"> and Diversity </span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Diverse Perspectives:</b> Connecting with people from various industries,
                    regions, or backgrounds opens up new perspectives, fostering innovation and
                    cross-cultural understanding.
                  </li>
                  <li>
                    <b>Global Connections: </b> For international events, attendees can connect with
                    peers across the globe, expanding their professional and cultural reach.
                  </li>
                  <li>
                    <b>Inclusive Environment:</b> Building connections fosters an inclusive
                    atmosphere where everyone feels valued, heard, and supported.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img src={Invovation} alt="" style={{ height: "300px" }} className="img-fluid" />
            </section>
            <section className="col-md-7">
              <h2>
                7. Increased Innovation<span className="heading-col"> and Creativity</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Cross-Industry Collaboration:</b> By connecting individuals from different
                    sectors, new ideas and creative solutions emerge, leading to breakthroughs and
                    innovative projects.
                  </li>
                  <li>
                    <b>Sharing Best Practices:</b> Attendees can share their unique experiences and
                    best practices, contributing to the growth and development of all participants.
                  </li>
                  <li>
                    <b>Collaborative Problem Solving:</b> Strong connections among attendees lead to
                    collaborative approaches to solving industry challenges.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img src={companyevnet} alt="" style={{ height: "300px" }} className="img-fluid" />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>
                8. Increased Engagement in <span className="heading-col"> Event Activities </span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b> Active Involvement: </b> Attendees who feel connected are more likely to
                    participate in event activities, from social mixers to workshops and
                    presentations.
                  </li>
                  <li>
                    <b>Leadership Roles: </b> Connecting with peers can encourage attendees to take
                    leadership roles within the event, increasing engagement and ownership.
                  </li>
                  <li>
                    <b> Creating Long-Term Networks:</b> The connections made during an event often
                    continue beyond the event itself, creating lasting relationships that benefit
                    both individuals and their organizations.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img src={CompanyEvnet1} alt="" style={{ height: "300px" }} className="img-fluid" />
            </section>
            <section className="col-md-7">
              <h2>
                9. Better Event <span className="heading-col">Experience</span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>Interactive Sessions:</b> With enhanced connections, attendees are more likely
                    to engage in interactive sessions and workshops, creating a more dynamic event
                    experience.
                  </li>
                  <li>
                    <b>Follow-Up Collaboration:</b> Post-event collaborations become more frequent as
                    attendees continue to engage with each other after the event ends.
                  </li>
                  <li>
                    <b>Resource Sharing:</b> Attendees can share resources, knowledge, and event
                    takeaways, making the event more valuable to everyone involved.
                  </li>
                </ul>
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img src={CompanyEvnet2} alt="" style={{ height: "300px" }} className="img-fluid" />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>
                10. Promotion of Inclusion{" "}
                <span className="heading-col"> and Equal Opportunities </span>
              </h2>
              <p className="text-left font">
                <ul>
                  <li>
                    <b>Connecting Diverse Attendees:</b> Encouraging connections across different
                    demographic groups ensures a more inclusive and welcoming event.
                  </li>
                  <li>
                    <b>Access to Opportunities:</b> Stronger networks help attendees from all
                    backgrounds gain access to professional and personal opportunities that may
                    otherwise have been unavailable.
                  </li>
                  <li>
                    <b> Support for Marginalized Groups:</b> Creating opportunities for
                    underrepresented groups to connect ensures that everyone has a voice at the
                    event.
                  </li>
                </ul>
              </p>
            </section>
          </section>
        </section>
      </section>
      <GetConfrenecTounch />
      <div data-aos="fade-right" id="footer">
        <Footer />
      </div>
    </>
  );
};

export default WhyConnectarData;
