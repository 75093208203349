import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import WYSIWYGEditor from "./WYSIWYGEditor";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Typography,
  Card,
  Grid,
  Modal,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Swal from "sweetalert2";
import DeleteIcon from '@mui/icons-material/Delete';
 
const style = {
  position: "absolute",
  top: { xs: 400, sm: 400, md: 440, xl: 420 },
  width: 700,
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  pb: 3,
};
const stripHtml = (html) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};


const EditPage = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [slug, setSlug] = useState("");
  const [featuredImage, setFeaturedImage] = useState(null);
  const [status, setStatus] = useState("draft");
  const [categories, setCategories] = useState("");
  const apiBaseUrl = process.env.REACT_APP_API_PATH;
  const [isButtonDisabled, setIsButtonDisabled] = useState({ draft: false, publish: false });
    const [summary, setSummary] = useState("");
 const [hightitle, setHightitle] = useState("");
  const navigate = useNavigate();
  const fetchPage = async () => {
    try {
      const response = await axios.get(`${apiBaseUrl}/pages/getdetails/${id}`);
      setTitle(response.data.title);
      setHightitle(response.data.hightitle);
      setSummary(response.data.summary);
      setContent(response.data.content);
      setSlug(response.data.slug);
      setFeaturedImage(response.data.featuredImages[0]);
      const metadata = response.data.metadata || {};
     
      setStatus(response.data.status);
      setCategories(response.data.categories?.join(", ") || "");
    } catch (err) {
      console.log("Failed to load page data",err)
    }
  };
  useEffect(() => {
    fetchPage();
  }, [id, apiBaseUrl]);

  const handleFileChange = (e) => {
    setFeaturedImage(e.target.files[0]);
  };

 
  const handleSubmit = async (e,newStatus) => {
    e.preventDefault();
    setIsButtonDisabled((prevState) => ({
      ...prevState,
      [newStatus === "published" ? "publish" : "draft"]: true,
    }));
    const formData = new FormData();
    formData.append("title", title);
    formData.append("summary", summary);
    formData.append("hightitle", hightitle);
    formData.append("content", content);
    if (featuredImage && typeof featuredImage !== "string") {
      formData.append("featuredImage", featuredImage);
    }
    formData.append("status", newStatus || status);
    formData.append(
      "categories",
      categories.split(",").map((cat) => cat.trim())
    );
 

    try {
      await axios.put(`${apiBaseUrl}/admin/pages/edit/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      Swal.fire({
        icon: "success",
        title: newStatus === "published" ? "Page Published!" : "Draft Saved!",
        text: newStatus === "published"
          ? "Your page has been published successfully."
          : "The draft has been saved successfully.",
      });
      navigate("/cms/pages");
    
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update the page. Please try again.",
      });
    }
    finally {
      setIsButtonDisabled((prevState) => ({
        ...prevState,
        [newStatus === "published" ? "publish" : "draft"]: false,
      }));
    }
  };

  const handleSaveData = async (e) => {
    e.preventDefault();
    setIsButtonDisabled((prevState) => ({ ...prevState, save: true }));
    const formData = new FormData();
    formData.append("title", title);
    formData.append("hightitle",hightitle);
    formData.append("summary", summary);
    formData.append("content", content);
    if (featuredImage && typeof featuredImage !== "string") {
      formData.append("featuredImage", featuredImage);
    }
    formData.append("status", status);
    formData.append(
      "categories",
      categories.split(",").map((cat) => cat.trim())
    );

    try {
      await axios.put(`${apiBaseUrl}/admin/pages/edit/${id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      navigate("/cms/pages");
      Swal.fire({
        icon: "success",
        title: "Page Saved!",
        text: "Page saved successfully!",
      });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to update the page. Please try again.",
      });
    } finally {
      setIsButtonDisabled((prevState) => ({ ...prevState, save: false }));
    }
  };
  const handleBack = (e) => {
    e.preventDefault();
    navigate(-1);
  };

  const handleDelete = async () => {
    setIsButtonDisabled((prevState) => ({ ...prevState, delete: true }));
    try {
      await axios.delete(`${apiBaseUrl}/admin/pages/${id}`);
       Swal.fire("Great!", "Page deleted successfully.", "success");
      navigate("/cms/pages");
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to delete the page. Please try again.",
      });
    } finally {
      setIsButtonDisabled((prevState) => ({ ...prevState, delete: false }));
    }
  };
  return (
<>
       <DashboardLayout>
          <Sidenav brandName="Connectar" routes={routes} />
      <MDBox pb={3} >
        
        <Grid container spacing={6}>
          <Grid item xs={12} lg={12}>
        <Card sx={{ ...style,
            width: {
              xs: "90%",
              sm: "80%",
              md: "500px",
              lg: "60%",
              xl: "90%",
            },
          }}>
      
        <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        sx={{
          backgroundColor: "#1e90ff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
        Edit Page
        </MDTypography>
        <MDButton
                 style={{
                  color: "black",
                  border: "1px solid white",
                  background: "white",
                  marginRight: "10px",
                }} 
                   onClick={handleBack}
                  >
                <ArrowBackIcon style={{ marginRight: "8px" }} />   Back
                </MDButton>
      </MDBox>
          <form onSubmit={handleSubmit}>
          <Grid container spacing={2}  px={4} py={2}>
              <Grid item xs={6} mt={2}>
               <TextField
                                 label="Page Title"
                                 variant="outlined"
                                 fullWidth
                                 value={title}
                                 onChange={(e) => setTitle(e.target.value)}
                                 required
                               />
              </Grid>
                <Grid item xs={6} mt={2}>
                            <TextField
                                label="Page Highlight Title"
                                variant="outlined"
                                fullWidth
                                value={hightitle}
                                onChange={(e) => setHightitle(e.target.value)}
                              />
                            </Grid>
                <Grid item xs={12}>
                            <TextField
                                label="Page Summary"
                                variant="outlined"
                                fullWidth
                                value={summary}
                                multiline
                                rows={4}    
                                onChange={(e) => setSummary(e.target.value)}
                                required
                              />
                            </Grid>
              <Grid item xs={12}>
                <WYSIWYGEditor value={content} onChange={setContent} />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl fullWidth>
                <Button
                      component="label"
                      variant="outlined"
                      startIcon={<CloudUploadIcon style={{ color: "#7b809a" }} />}
                      sx={{ marginRight: "1rem" , "&:hover": {
                        borderColor: "#0d6efd",
                      }, }}
                    >
                      <span style={{ color: "#7b809a" }}> Upload Featured Image</span>
                      <input
                        type="file"
                        accept="application/pdf,.pdf, .jpg, .jpeg, .png, .doc, .docx, .txt"
                        hidden
                        onChange={handleFileChange}
                      />
                    </Button>
                  {featuredImage && (
                    <Typography sx={{ marginTop: "8px", fontSize:"10px" }}>
                      {typeof featuredImage === "object"
                        ? `Selected File: ${featuredImage.name}`
                        : `Current Image: ${featuredImage}`}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
              <FormControl
  fullWidth
  margin="normal"
  sx={{ fontSize: "14px", '& .MuiInputBase-root': { fontSize: "14px", padding: "12px" } }}
>
  <InputLabel>Categories</InputLabel>
  <Select
    value={categories}
    onChange={(e) => setCategories(e.target.value)}
    label="Categories"
    sx={{ fontSize: "14px", padding: "12px" }}
  >
  <MenuItem value="corporate">Corporate</MenuItem>
      <MenuItem value="Student">Student</MenuItem>
      <MenuItem value="conference">Conference & Event</MenuItem>
  </Select>
</FormControl>
              </Grid>
         
              <Grid item xs={4}>
                      <MDButton
                        color="info"
                        fullWidth
                        type="submit"
                        style={{
                          fontSize: "14px",
                        }} 
                        onClick={handleSaveData}
                        disabled={isButtonDisabled.save}
                      >
                      {isButtonDisabled.save ? "Saving Changes..." : "Save"}
                      </MDButton>
                    </Grid>
                    <Grid item xs={4}>
    {status === "published" ? (
      <MDButton
        color="info"
        fullWidth
        onClick={(e) => handleSubmit(e, "draft")}
        disabled={isButtonDisabled.draft}
        style={{ fontSize: "14px" }}
      >
        {isButtonDisabled.draft ? "Saving Draft..." : "UnPublish"}
      </MDButton>
    ) : (
      <MDButton
        color="info"
        fullWidth
        onClick={(e) => handleSubmit(e, "published")}
        disabled={isButtonDisabled.publish}
        style={{ fontSize: "14px" }}
      >
        {isButtonDisabled.publish ? "Saving Changes..." : "Publish"}
      </MDButton>
    )}
  </Grid>
                    <Grid item xs={4}>
                      <MDButton
                        color="error"
                        type="submit"
                        fullWidth
                        onClick={handleDelete}
                        disabled={isButtonDisabled.delete}
                        style={{
                          marginRight: "10px",
                          fontSize: "14px",
                        }} 
                      >
                       <DeleteIcon
         color="white"
         style={{ cursor: "pointer", marginRight: "8px" }}
       /> 
        {isButtonDisabled.delete ? "Deleting..." : "Delete"}
                      </MDButton>
                    </Grid>
                   
            
            </Grid>
          </form>
        </Card>
        </Grid>
        </Grid>
      </MDBox>
      </DashboardLayout>
   
      <ToastContainer limit={1} className="custom-toast"/>
      </>
  );
};

export default EditPage;
