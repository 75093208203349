import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "assets/images/small-logos/image.png";
import Swal from "sweetalert2/dist/sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "MainComponet/Header";
import { useFormik } from "formik";
import Footer from "MainComponet/Footer";
import "MainComponet/css/custom.css";
import LandingHeader from "MainComponet/Students/LandingHeader";
import LandingFooter from "MainComponet/landingfooter";
function Basic() {
  const navigate = useNavigate();
  const [showlogin, setShowLogin] = useState(true);
  const [employee, setNewEmployee] = useState();
  const [password, setPassword] = useState("password");
  const [showIcon, setShowIcon] = useState(true);
  const { state } = useLocation();
  const id = state;
  const handleChange = (e) => {
    setShowLogin(false);
    setNewEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      setShowLogin(true);

      axios
        .post(`${process.env.REACT_APP_API_PATH}/login`, employee)
        .then((res) => {
          if (res.data.status == 200) {
            localStorage.setItem("token", res.data.user.token);
            localStorage.setItem("role", res.data.user.role);

            // localStorage.setItem("lastquestion", res.data.user.currentQuestion);
            if (res.data.user.role == "user") {
              localStorage.setItem("lastcategory", res.data.user.currentCategory);
              localStorage.setItem("selectedOptions", JSON.stringify(res.data.user.hobbies));
            }

            Swal.fire("Done!", "User logged in Successfully", "success");

            if (res.data.user.role === "admin") {
              navigate("/companies");
            } else if (res.data.user.role === "company") {
              navigate("/addusers");
            } else if (res.data.user.role === "user") {
              if (!res.data.user.iscomplete) {
                navigate("/welcomeQuestions");
              } else if (state) {
                navigate(`/feedback/${id}`);
              } else {
                navigate("/userprofile");
              }
            }

            toast.success(" Sign In Successfully!", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((err) => console.log(err));
    },
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .post(`${process.env.REACT_APP_API_PATH}/validuser`, { token })
        .then((res) => {
          if (res.data.status == 200) {
            if (res.data.data.role === "admin") {
              navigate("/companies");
            } else if (res.data.data.role === "company") {
              navigate("/addusers");
            } else if (res.data.data.role === "user") {
              navigate("/userprofile");
            }
          }
        })
        .catch((err) => console.log(err));
    }
  });

  const handleShowPassword = () => {
    if (password === "password") {
      setPassword("text");
      setShowIcon(false);
      return;
    }

    setPassword("password");
    setShowIcon(true);
  };

  const isLandingPage = location.pathname === "/login";
  const isCorporateRoute = location.pathname.startsWith("/corporate");
  const isConferenceRoute = location.pathname.startsWith("/conference");
  const isStudentRoute = location.pathname.startsWith("/student");
  return (
    <>
      {isLandingPage ? <LandingHeader /> : <Header />}{" "}
      <div
        className="border1"
        data-aos="fade-right"
        data-aos-easing="linear"
        data-aos-duration="1500"
      >
        <div className=" container ">
          <form onSubmit={formik.handleSubmit}>
            <section class=" gradient-custom">
              <div class="container">
                <div class="row d-flex justify-content-center align-items-center">
                  <h2 className="text-center mt-4">
                    <span
                      className={` ${
                        isStudentRoute
                          ? "heading-color"
                          : isCorporateRoute
                          ? "cor-col"
                          : isConferenceRoute
                          ? "heading-col"
                          : "cor-col"
                      }`}
                    >
                      Sign
                    </span>{" "}
                    In
                  </h2>
                  <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div
                      class="card bg-white text-black"
                      style={{ borderRadius: "1rem", boxShadow: "#0d6efd73 0px 2px 4px" }}
                    >
                      <div class="card-body p-5">
                        <div class="mb-md-5">
                          <div class="mb-3">
                            <label for="email" class="form-label">
                              Email address
                            </label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              required
                              name="email"
                              onChange={handleChange}
                            />
                          </div>

                          <div class="mb-3">
                            <label for="password" class="form-label">
                              Password
                            </label>
                            <div class="input-group">
                              <input
                                type={password}
                                id="password"
                                name="password"
                                onChange={handleChange}
                                className="form-control"
                                required
                              />
                              {isStudentRoute ? (
                                <button
                                  className="btn-Pink custom-btn"
                                  type="button"
                                  onClick={handleShowPassword}
                                >
                                  {showIcon ? (
                                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                  ) : (
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                  )}
                                </button>
                              ) : (
                                <button
                                  className={`btn  custom-btn ${
                                    isCorporateRoute
                                      ? "btn-primary"
                                      : isConferenceRoute
                                      ? "btn-success"
                                      : "btn-primary"
                                  }`}
                                  type="button"
                                  onClick={handleShowPassword}
                                >
                                  {showIcon ? (
                                    <i className="fa fa-eye-slash" aria-hidden="true"></i>
                                  ) : (
                                    <i className="fa fa-eye" aria-hidden="true"></i>
                                  )}
                                </button>
                              )}
                              {/* <button className="btn btn-outline-primary custom-btn" type="button" onClick={handleShowPassword}>
      {showIcon ? (
        <i className="fa fa-eye-slash" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-eye" aria-hidden="true"></i>
      )}
    </button> */}
                            </div>
                          </div>

                          <p class="small mb-5 pb-lg-2  text-center">
                            <Link
                              to="/authentication/sign-in/forget"
                              className={`text-center ${
                                isStudentRoute
                                  ? "heading-color"
                                  : isCorporateRoute
                                  ? "cor-col"
                                  : isConferenceRoute
                                  ? "heading-col"
                                  : "cor-col"
                              }`}
                            >
                              Forgot Password?
                            </Link>
                          </p>
                          <div className=" text-center">
                            {/* <button className="btn btn-primary d-block w-100" type="submit" disabled={showlogin}>
                      Sign in
                    </button> */}
                            {isStudentRoute ? (
                              <button className="btn-Pink d-block w-100" disabled={showlogin}>
                                Sign in
                              </button>
                            ) : (
                              <button
                                className={`btn d-block w-100 ${
                                  isCorporateRoute
                                    ? "btn-primary"
                                    : isConferenceRoute
                                    ? "btn-success"
                                    : "btn-primary"
                                }`}
                                disabled={showlogin}
                              >
                                Sign in
                              </button>
                            )}
                            <ToastContainer limit={1} className="custom-toast"/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
      <div className="border1">{isLandingPage ? <LandingFooter /> : <Footer />}</div>
    </>
  );
}

export default Basic;
