import React, { useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import connectstaff from "../assets/images/FrontPage/connectstaff.png";
import team from "../assets/images/FrontPage/what-we-do.svg";
import referencability from "../assets/images/FrontPage/referencability.svg";
import methodology from "../assets/images/FrontPage/methodology.svg";
import Employeeretention from "../assets/images/FrontPage/Employeeretention.webp";
import Conflict from "../assets/images/FrontPage/Conflict.jpg";
import GetInTouch from "./GetInTouch";
import AOS from "aos";
import "aos/dist/aos.css";
import "MainComponet/css/custom.css";
function WhyTheConnectar() {
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);

  return (
    <>
      <Header />
      <figure>
        <section className="border1">
          <section className="container">
            <section className="row align-items-center">
              <section className="col-sm-12 bannerContent" data-aos="fade-right">
                <h2>
                  {" "}
                  Why <span className="cor-col"> The Connectar? </span>
                </h2>
                <p className="text-left font">
                  Most of us have heard someone say, “I would have left that job long ago if it
                  weren’t for the people I worked with”. Imagine you could replicate this, but on a
                  global scale, where you aren’t limited by geographic or departmental separation;
                  and instead of limiting the staff within your organisation to dealing only with
                  other employees they typically engage with, you open up the opportunity for them
                  to connect with anyone across the organisation:
                </p>
              </section>
            </section>
          </section>
        </section>
      </figure>
      <section className="content-widget">
        <section className="container">
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img
                src={connectstaff}
                alt=""
                style={{
                  height: "250px",
                  width: "300px",
                }}
                className="img-fluid"
              />
            </section>
            <section className="col-md-7">
              <h2>1. Collaboration</h2>
              <p className="text-left font">
                When employees feel connected to one another, regardless of where they are located
                or which department they belong to, they are more likely to collaborate effectively.
                This collaboration can lead to the sharing of ideas, pooling of resources, and
                ultimately, better problem-solving.
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img
                src={team}
                alt=""
                style={{
                  height: "300px",
                  width: "300px",
                }}
                className="img-fluid"
              />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>
                2.Team <span className="cor-col">Cohesion</span>
              </h2>
              <p className="text-left font">
                Connection fosters a sense of belonging and unity among team members. Even if they
                are physically distant or work in different departments, feeling connected helps
                individuals understand that they are part of a larger team working towards common
                goals. This cohesion improves morale and overall productivity.
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img
                src={referencability}
                alt=""
                style={{
                  height: "300px",
                  width: "300px",
                }}
                className="img-fluid"
              />
            </section>
            <section className="col-md-7">
              <h2>
                3. Knowledge <span className="cor-col">Sharing </span>
              </h2>
              <p className="text-left font">
                In a connected workplace, information flows more freely. Employees are more inclined
                to share their expertise, experiences, and insights with others, leading to a more
                knowledgeable and skilled workforce. This can be particularly beneficial in
                environments where different departments have complementary skills or knowledge.
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img src={methodology} alt="" height="400px" width="400px" className="img-fluid" />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>4. Innovation</h2>
              <p className="text-left font">
                When people from diverse backgrounds and areas of expertise come together, it
                creates fertile ground for innovation. Connection breaks down silos and encourages
                cross-pollination of ideas, which can spark creativity and lead to innovative
                solutions to problems.
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center">
              <img
                src={Employeeretention}
                alt=""
                height="400px"
                width="400px"
                className="img-fluid"
              />
            </section>
            <section className="col-md-7">
              <h2>
                5. Employee <span className="cor-col">Engagement and Retention </span>
              </h2>
              <p className="text-left font">
                Feeling connected to one's colleagues and the organization as a whole fosters a
                sense of loyalty and commitment. Employees who feel supported and valued are more
                likely to be engaged in their work and less likely to seek opportunities elsewhere.
              </p>
            </section>
          </section>
          <section className="row align-items-center " data-aos="fade-right">
            <section className="col-md-5 text-center order-md-2">
              <img src={Conflict} alt="" height="400px" width="400px" className="img-fluid" />
            </section>
            <section className="col-md-7 order-md-1">
              <h2>
                6. Conflict <span className="cor-col"> Resolution</span>
              </h2>
              <p className="text-left font">
                Strong connections between employees can also facilitate the resolution of
                conflicts. When individuals have established relationships built on trust and mutual
                respect, they are more likely to address conflicts directly and constructively,
                rather than letting them fester and escalate.
              </p>
            </section>
          </section>
        </section>
      </section>
      <GetInTouch />
      <div data-aos="fade-right" id="footer">
        {" "}
        <Footer />
      </div>
    </>
  );
}

export default WhyTheConnectar;
