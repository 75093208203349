import React, { useEffect, useRef, useState } from "react";
import LandingHeader from "./LandingHeader";
import BannerImage from "../../assets/images/FrontPage/banner-img.svg";
import confrencebanner from "../../assets/images/confrence/evnt.svg";
import studentBanner from "../../assets/images/Studentpage/studentmain.svg";
import UsersGroupIcon1 from "../../assets/images/small-logos/users-group1.svg";
import FriendshipIcon1 from "../../assets/images/small-logos/carbon_friendship1.svg";
import HandshakeIcon1 from "../../assets/images/small-logos/handshake1.svg";
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
import LandingFooter from "MainComponet/landingfooter";
import UsersGroupIcon from "../../assets/images/small-logos/users-group.svg";
import FriendshipIcon from "../../assets/images/small-logos/carbon_friendship.svg";
import HandshakeIcon from "../../assets/images/small-logos/handshake.svg";
import welcome from "../../assets/images/FrontPage/welcome.svg";
import Videoplayer from "MainComponet/Videoplayer";
const LandingPage = () => {
  const videoPlayRef = useRef(null);
  const [showHowWorkModal, setShowHowWorkModal] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);
  const corporateRef = useRef(null);
  const conferenceRef = useRef(null);
  const studentRef = useRef(null);
  const scrollToSection = (ref) => {
    const section = ref.current;
    const offset = (window.innerHeight - section.offsetHeight) / 2;
    const scrollPosition = section.offsetTop - offset;
    window.scrollTo({ top: scrollPosition, behavior: "smooth" });
  };
  // useEffect(() => {
  //   var tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  //   tooltipTriggerList.forEach((tooltipTriggerEl) => {
  //     new bootstrap.Tooltip(tooltipTriggerEl);
  //   });
  // }, []);

  const openWorkModal = () => {
    setShowHowWorkModal(true);
    if (videoPlayRef.current) {
      videoPlayRef.current.currentTime = 0;
      videoPlayRef.current.play();
    }
  };

  const closeWorkModal = () => {
    setShowHowWorkModal(false);
    if (videoPlayRef.current) {
      videoPlayRef.current.pause();
      videoPlayRef.current.currentTime = 0;
    }
  };
  return (
    <>
      <LandingHeader />
      <figure>
        <section className="container">
          {/* <div className="text-center mt-5">

  <div className="row ">
    <div className="col-md-4 mb-4">
      <div 
      className="tooltip-container" data-bs-toggle="tooltip" data-bs-placement="top" title="Go to Corporate"
       >
      <NavLink to="/corporate/home" >
        <img
          src={UsersGroupIcon1}
          alt="Corporate"
          style={{ cursor: "pointer", width: "120px", height: "120px" }}
          onClick={() => scrollToSection(corporateRef)}
        />
          <h3 className="text-dark">
              Corporate <span className="cor-col">Connection Platform</span>
              </h3>
              </NavLink>
      </div>
    </div>

    <div className="col-md-4 mb-4">
      <div className="tooltip-container" data-bs-toggle="tooltip" data-bs-placement="top" title="Go to Conference">
      <NavLink to="/conference/home">
        <img
          src={FriendshipIcon1}
          alt="Conference"
          style={{ cursor: "pointer", width: "120px", height: "120px" }}
          onClick={() => scrollToSection(conferenceRef)}
        />
         <h3 className="text-dark">
              Conference &  <span className="heading-col">Event Connection Platform</span>
              </h3>
              </NavLink>
      </div>
    </div>

    <div className="col-md-4 mb-4">
      <div className="tooltip-container" data-bs-toggle="tooltip" data-bs-placement="top" title="Go to Student">
      <NavLink to="/student/home">
        <img
          src={HandshakeIcon1}
          alt="Student"
          style={{ cursor: "pointer", width: "120px", height: "120px" }}
          onClick={() => scrollToSection(studentRef)}
        />
        <h3 className="text-dark">
              Student  <span className="heading-color">Connection Platform</span>
              </h3>
        </NavLink>
      </div>
    </div>

  </div>
  
</div> */}

          <section className="content-widget" data-aos="fade-right">
            <div className="row align-items-center aos-init aos-animate">
              <div className="col-md-5 text-center  order-md-2">
                <img src={welcome} alt="" />
              </div>
              <div className="col-md-7 ">
                <h2>
                  Welcome to <span className="cor-col">The Connectar</span>
                </h2>
                <p className="font text-left">
                  At The Connectar, we believe that meaningful connections are the foundation of
                  success—whether in the workplace, on campus, or at events. Our platform harnesses
                  a unique, compatibility-driven algorithm to connect individuals based on shared
                  values, interests, and goals. By fostering these genuine relationships, we help:
                </p>

                <ul className="font">
                  <li>
                    <b>Organisations</b> build collaborative, inclusive, and engaged workforces.
                  </li>
                  <li>
                    <b>Students</b> thrive through stronger connections, increased engagement, and
                    personal growth.
                  </li>
                  <li>
                    <b>Event attendees</b> unlock opportunities for networking, innovation, and
                    shared success.
                  </li>
                </ul>
                <p className="font text-left">
                  No matter where you are - across departments, campuses, or industries, The
                  Connectar breaks down barriers to create stronger, more connected communities.
                  Start building better connections today.
                </p>
              </div>
            </div>
          </section>
        </section>
      </figure>
      {/* video */}
      {showHowWorkModal && (
        <section className="container" style={{ cursor: "pointer" }}>
          <div
            className="modal fade show"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            tabIndex="-1"
            role="dialog"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="Close_icon" onClick={closeWorkModal}>
                  <i className="fa fa-close"></i>
                </div>
                <div className="modal-body">
                  <Videoplayer ref={videoPlayRef} />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      {/* three cols */}

      {/* <section className="section-widget mt-5 mb-5" >
  <section className="container">
    <section className="row text-center justify-content-center gx-4"> 
      
      <section className="col-md-3 cols-widget primary-col three-cols hover-effect" data-aos="fade-right" onClick={() => scrollToSection(corporateRef)} style={{ cursor: "pointer", padding: "15px",marginRight:"5%" }}>
        <i className="mb-3 d-block">
          <img src={UsersGroupIcon} alt=""/>
        </i>
        <h4 className="mb-2">Corporate Connection Platform</h4>
        <NavLink to="/corporate/home" className="know-more-btn">Know More</NavLink>
      </section>

      <section className="col-md-3 cols-widget hover-effect conf-back" data-aos="fade-right" onClick={() => scrollToSection(conferenceRef)} style={{ cursor: "pointer", padding: "15px",marginRight:"5%"  }}>
        <i className="mb-3 d-block">
          <img src={FriendshipIcon} alt=""/>
        </i>
        <h4 className="mb-2">Conference & Event Connection Platform</h4>
        <NavLink to="/conference/home" className="know-more-btn">Know More</NavLink>
      </section>

      <section className="col-md-3 cols-widget hover-effect student-col" data-aos="fade-right" onClick={() => scrollToSection(studentRef)} style={{ cursor: "pointer", padding: "15px" }}>
        <i className="mb-3 d-block">
          <img src={HandshakeIcon} alt=""/>
        </i>
        <h4 className="mb-2">Student Connection Platform</h4>
        <NavLink to="/student/home" className="know-more-btn">Know More</NavLink>
      </section>

    </section>
  </section>
</section> */}
      <section className="container">
        <section className="content-widget">
          <div className="row align-items-center mt-2" data-aos="fade-right" ref={corporateRef}>
            <div className="col-md-5 text-center">
              <img src={BannerImage} alt="" />
            </div>
            <div className="col-md-7">
              <h2>
                Corporate <span className="cor-col">Connection Platform</span>
              </h2>
              <p className="font text-left">
                Helping your organisation to improve job satisfaction, productivity and wellness,
                through connecting your employees regardless of geographic or departmental
                separation.
              </p>

              <div className="button-container" style={{ display: "flex", gap: "10px" }}>
                <NavLink to="/corporate/home" className="btn btn-primary">
                  Read More
                </NavLink>
                {/* <button
                  className="btn btn-primary d-inline-flex align-items-center"
                  onClick={openWorkModal}
                >
                  How It Works ?{" "}
                  <i
                    className="fa fa-play-circle-o"
                    aria-hidden="true"
                    style={{ color: "white", fontSize: "22px" }}
                  ></i>
                </button> */}
              </div>
            </div>
          </div>
          <div
            className="row align-items-center aos-init aos-animate mt-2"
            data-aos="fade-right"
            ref={conferenceRef}
          >
            <div className="col-md-5 text-center  order-md-2">
              <img src={confrencebanner} alt="" />
            </div>
            <div className="col-md-7 ">
              <h2>
                Conference and <span className="heading-col">Event Connection Platform</span>
              </h2>
              <p className="font text-left">
                Helping your event or conference participants improve engagement, collaboration, and
                overall event success by fostering meaningful connections among attendees,
                regardless of their industry, role, or geographic location. Through strategic
                networking, we help individuals thrive both professionally and socially,
                contributing to a more engaging and impactful event experience.
              </p>
              <div className="button-container" style={{ display: "flex", gap: "10px" }}>
                <NavLink to="/conference/home" className="btn btn-success">
                  Read More
                </NavLink>
                {/* <button
                                className="btn btn-success d-inline-flex align-items-center"
                                onClick={openWorkModal}
                              >
                                How It Works ?{" "}
                                <i
                                  className="fa fa-play-circle-o"
                                  aria-hidden="true"
                                  style={{ color: "white", fontSize: "22px" }}
                                ></i>
                              </button> */}
              </div>
            </div>
          </div>

          <div className="row align-items-center mt-2" data-aos="fade-right" ref={studentRef}>
            <div className="col-md-5 text-center">
              <img src={studentBanner} alt="" />
            </div>
            <div className="col-md-7">
              <h2>
                Student <span className="heading-color">Connection Platform</span>
              </h2>
              <p className="font text-left">
                Helping your university or college improve student engagement, academic success, and
                well-being by fostering connections among students, regardless of their academic
                department, study year, or campus location. Through meaningful connections, we help
                students thrive both socially and academically, building a stronger campus
                community.
              </p>
              <div className="button-container" style={{ display: "flex", gap: "10px" }}>
                <NavLink to="/student/home" className="btn-Pink ">
                  Read More
                </NavLink>
                {/* <button
                                className=" btn-Pink d-inline-flex align-items-center"
                                onClick={openWorkModal}
                              >
                                How It Works ?{" "}
                                  <i
                                  className="fa fa-play-circle-o"
                                  aria-hidden="true"
                                  style={{ color: "white", fontSize: "22px", marginLeft:"10px" }}
                                ></i>
                              </button> */}
              </div>
            </div>
          </div>
        </section>
      </section>
      <div data-aos="fade-right" id="footer" className="mt-5">
        {" "}
        <LandingFooter />{" "}
      </div>
    </>
  );
};

export default LandingPage;
