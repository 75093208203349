import React, { useEffect, useRef, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import Header from "./Header";
import Header from "../../MainComponet/Header";
import connectVideo from "../../assets/images/Video/Connectar08.e09952939f4fa6f9965f.mp4";
import Videoplayer from "../Videoplayer";
import BannerImage from "../../assets/images/Studentpage/banner-img-student.svg";
import "./Studentevent.css";
import whyConnectar from "../../assets/images/Studentpage/whyconnecter.svg";

import Organisation from "../../assets/images/Studentpage/stu-wht-do.svg";
import referencability from "../../assets/images/Studentpage/Stu-referencability.svg";
import Methodology from "../../assets/images/Studentpage/Stu-methodology.svg";
import empathyIcon from "../../assets/images/Studentpage/Empthay.svg";
import reflectionIcon from "../../assets/images/Studentpage/brain.svg";

import communicationIcon from "../../assets/images/Studentpage/Communication.svg";
import socialCognitionIcon from "../../assets/images/Studentpage/social-cognition.svg";
import ideaIcon from "../../assets/images/Studentpage/idea.svg";
import altruismIcon from "../../assets/images/Studentpage/hand-holding-user.png";
import Avatar from "../../assets/images/Studentpage/avatar-group.png";
import UsersGroupIcon from "../../assets/images/Studentpage/stu-users-group-copy.svg";
import FriendshipIcon from "../../assets/images/Studentpage/stu-carbon_friendship.svg";
import HandshakeIcon from "../../assets/images/Studentpage/stu-handshake.svg";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";
const StudentMainPage = () => {
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [videoPlayed, setVideoPlayed] = useState(false);
  const videoRef = useRef(null);
  const videoPlayRef = useRef(null);
  const [showHowWorkModal, setShowHowWorkModal] = useState(false);
  const isFirstVisit = localStorage.getItem("isFirstTimeVisit");

  // useEffect(() => {
  //   if (!isFirstVisit) {
  //     localStorage.setItem("isFirstTimeVisit", true);
  //     setShowVideoModal(true);
  //   }

  //   const handleEscKey = (event) => {
  //     if (event.keyCode === 27) closeVideoModal();
  //   };
  //   document.addEventListener("keydown", handleEscKey);

  //   return () => {
  //     document.removeEventListener("keydown", handleEscKey);
  //   };
  // }, [isFirstVisit]);

  // const openVideoModal = () => {
  //   setShowVideoModal(true);
  //   setVideoPlayed(true);
  //   if (videoRef.current) {
  //     videoRef.current.currentTime = 0;
  //     videoRef.current.play();
  //   }
  // };
  // const closeVideoModal = () => {
  //   setShowVideoModal(false);
  //   if (videoRef.current) {
  //     videoRef.current.currentTime = 0;
  //     videoRef.current.pause();
  //   }
  // };

  // const openWorkModal = () => {
  //   setShowHowWorkModal(true);
  //   if (videoPlayRef.current) {
  //     videoPlayRef.current.currentTime = 0;
  //     videoPlayRef.current.play();
  //   }
  // };

  // const closeWorkModal = () => {
  //   setShowHowWorkModal(false);
  //   if (videoPlayRef.current) {
  //     videoPlayRef.current.pause();
  //     videoPlayRef.current.currentTime = 0;
  //   }
  // };
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);
  return (
    <>
      {/* darker #7DAF82 */}
      <Header />
      <figure>
        <section className="container">
          <section className="row align-items-center" data-aos="fade-right">
            <section className="col-sm-6 bannerContent">
              <h1>
                The <span className="heading-color">Connectar </span>
              </h1>
              <h3>Student Connection Platform</h3>
              <p className="font text-left">
                Helping your university or college improve student engagement, academic success, and
                well-being by fostering connections among students, regardless of their academic
                department, study year, or campus location. Through meaningful connections, we help
                students thrive both socially and academically, building a stronger campus
                community.
              </p>

              {/* <div className="button-container" style={{ display: "flex", gap: "10px" }}>
                <button
                  className="btn-Pink d-inline-flex align-items-center main-btn"
                  onClick={openVideoModal}
                >
                  Why Connectar{" "}
                  <i
                    class="fa fa-play-circle-o ms-2"
                    aria-hidden="true"
                    style={{ color: "white", fontSize: "25px" }}
                  ></i>{" "}
                </button>
                <button
                  className="btn-Pink d-inline-flex align-items-center main-btn"
                  onClick={openWorkModal}
                >
                  How It Works ?{" "}
                  <i
                    className="fa fa-play-circle-o ms-2"
                    aria-hidden="true"
                    style={{ color: "white", fontSize: "25px" }}
                  ></i>
                </button>
              </div> */}
            </section>
            <section class="col-sm-6">
              <img src={BannerImage} alt="" />
            </section>
          </section>
        </section>
        {/* Video Modal */}

        {/* {showVideoModal && (
          <section className="container" style={{ cursor: "pointer" }}>
            <div
              className="modal fade show"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              tabIndex="-1"
              role="dialog"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="Close_icon" onClick={closeVideoModal}>
                    <i className="fa fa-close"></i>
                  </div>
                  <div className="modal-body">
                    <video
                      ref={videoRef}
                      id="videoPlayer"
                      title="Video Title"
                      className="videoframe"
                      loop
                      autoPlay
                      controls
                      allow="autoplay; fullscreen;"
                      allowFullScreen
                      preload="none"
                      muted={!videoPlayed}
                      onCanPlay={() => videoRef.current?.play()}
                    >
                      <source src={connectVideo} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {showHowWorkModal && (
          <section className="container" style={{ cursor: "pointer" }}>
            <div
              className="modal fade show"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              tabIndex="-1"
              role="dialog"
            >
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="Close_icon" onClick={closeWorkModal}>
                    <i className="fa fa-close"></i>
                  </div>
                  <div className="modal-body">
                    <Videoplayer ref={videoPlayRef} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        )} */}
      </figure>

      <section class="section-widget success-col mb-4 " style={{ backgroundColor: "#C39E95" }}>
        <section class="container">
          <section class="row text-center">
            <section class="col-md-4 cols-widget" data-aos="fade-right">
              <i class="mb-3 d-block">
                <img src={UsersGroupIcon} alt="" />
              </i>
              <h4 class="mb-2">Enhanced Academic Collaboration</h4>
              <p>
                Students can form Study Groups, Peer Tutoring, and improved Academic Motivation,
                which results in building a strong network, leading to collaborative learning,
                sharing diverse perspectives and ideas, reinforcing their own knowledge, encouraging
                academic goals, and providing accountability.
              </p>
            </section>
            <section class="col-md-4 cols-widget" data-aos="fade-right">
              <i class="mb-3 d-block">
                <img src={FriendshipIcon} alt="" />
              </i>
              <h4 class="mb-2">Improved Mental Health and Well-being</h4>
              <p>
                Having friends and a support network reduces feelings of loneliness and
                homesickness, creates a safe environment for handling academic and personal stress
                and supportive relationships help students build resilience.
              </p>
            </section>
            <section class="col-md-4 cols-widget" data-aos="fade-right">
              <i class="mb-3 d-block">
                <img src={HandshakeIcon} style={{ color: "#A8CAAE" }} alt="" />
              </i>
              <h4 class="mb-2">Increased Retention and Graduation Rates</h4>
              <p>
                Students who feel connected are more likely to stay enrolled and complete their
                degree, reducing dropout rates, leading to more active involvement in
                extracurricular activities, and improved adjustment for new students enhancing the
                overall college experience.
              </p>
            </section>
          </section>
        </section>
      </section>

      <section className="container">
        <section className="content-widget">
          <div className="row align-items-center " data-aos="fade-right">
            <div className="col-md-5 text-center">
              <img src={whyConnectar} alt="" />
            </div>
            <div className="col-md-7">
              <h2>
                Why <span className="heading-color">The Connectar</span>
              </h2>
              <p className="font text-left">
                Improved connection among students at universities and colleges offers a wide range
                of benefits, contributing to both their academic success and personal well-being.
                Here is a comprehensive list of reasons:
              </p>
              <NavLink to="/student/why-the-connectar" className="btn-Pink ">
                Read More
              </NavLink>
            </div>
          </div>
          <div className="row align-items-center aos-init aos-animate" data-aos="fade-right">
            <div className="col-md-5 text-center  order-md-2">
              <img src={referencability} alt="" />
            </div>
            <div className="col-md-7 ">
              <h2>
                What does the <span className="heading-color">data tell us?</span>
              </h2>
              <p className="font text-left">
                The benefits of fostering meaningful connections at conferences and events are
                backed by extensive research and data. Studies consistently show that networking and
                collaboration are not only essential for attendee satisfaction but also for the
                long-term success of both individuals and organizations. From enhancing engagement
                and knowledge sharing to boosting innovation and reducing stress, the data
                underscores the pivotal role that connections play in making events more impactful.
                By leveraging the power of networking, event organizers can create more valuable
                experiences, ensuring greater satisfaction, retention, and business outcomes. Below
                are key insights that demonstrate the measurable benefits of prioritizing attendee
                connection at your next event.
              </p>
              <NavLink to="/student/why-you-should" className="btn-Pink ">
                Read More
              </NavLink>
            </div>
          </div>
          <div className="row align-items-center aos-init aos-animate" data-aos="fade-right">
            <div className="col-md-5 text-center mb-2">
              <img src={Methodology} alt="" />
            </div>
            <div className="col-md-7">
              <h2>
                <span className="heading-color">The Connectar</span> Methodology
              </h2>
              <p className="font text-left">
                At The Connectar, we are focused on helping event attendees make meaningful
                connections that last beyond the event. By fostering an environment of
                collaboration, support, and shared goals, we ensure that attendees can maximize
                their networking and gain the most value from each event experience.
              </p>
              <div className="button-container" style={{ display: "flex", gap: "10px" }}>
                <NavLink to="/student/methodology" className="btn-Pink ">
                  Read More
                </NavLink>
                {/* <button
                  className="btn-Pink d-inline-flex align-items-center"
                  onClick={openWorkModal}
                >
                  How It Works ?{" "}
                  <i
                    className="fa fa-play-circle-o ms-2"
                    aria-hidden="true"
                    style={{ color: "white", fontSize: "22px" }}
                  ></i>
                </button> */}
              </div>
            </div>
          </div>
          <div className="row align-items-center aos-init aos-animate mt-2" data-aos="fade-right">
            <div className="col-md-5 text-center order-2">
              <img src={Organisation} alt="" />
            </div>
            <div className="col-md-7 ">
              <h2>
                Why you should <span className="heading-color">do something now</span>
              </h2>
              <p className="font text-left">
                As the world adapts to new ways of working and collaborating, the need for
                meaningful connections at conferences and events has never been more crucial. With
                the rise of hybrid and virtual events, while technology offers convenience, it can
                also lead to a sense of disconnection. Attendees may feel isolated, missing out on
                the full experience of networking and engaging with peers. However, those who
                embrace connection-building strategies can transform this challenge into an
                opportunity to enhance event value and participant satisfaction.
              </p>
              <NavLink to="/student/whyNow" className="btn-Pink ">
                Read More
              </NavLink>
            </div>
          </div>
        </section>
      </section>

      <section
        className="section-widget  color-white Benefits mt-5"
        style={{ backgroundColor: "#C39E95" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={empathyIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Boosted Social Skills</h3>
                  <p className="font text-left">
                    The Connectar enhances students' communication, conflict resolution, and
                    leadership abilities through meaningful connections. By fostering regular
                    interactions with diverse peers, students naturally develop essential
                    interpersonal skills that prepare them for personal and professional success.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Empathy--> */}

            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={reflectionIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Career Advancement</h3>
                  <p className="font text-left">
                    The Connectar creates pathways to mentorship, networking opportunities, and
                    collaborative projects. By connecting students with peers, alumni, and industry
                    professionals, it opens doors to internships, job opportunities, and
                    entrepreneurial ventures that build strong career foundations.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Capacity for Reflection and Self-awareness--> */}
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={socialCognitionIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Cultural Exchange and Inclusion</h3>
                  <p className="font text-left">
                    The Connectar fosters meaningful relationships that bridge cultural gaps and
                    encourage inclusion. By connecting students from diverse backgrounds, it
                    promotes understanding, tolerance, and global awareness, creating a more
                    inclusive campus environment and preparing students for an interconnected world.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Social Cognition--> */}
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={ideaIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Improved Personal Growth</h3>
                  <p className="font text-left">
                    The Connectar empowers students to step out of their comfort zones, increasing
                    confidence and emotional intelligence. Through meaningful connections, students
                    discover shared interests, build a sense of purpose, and develop the skills
                    necessary for personal and professional growth.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Cultural and Emotional Intelligence--> */}
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={altruismIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Increased Engagement in Campus and Community Initiatives</h3>
                  <p className="font text-left">
                    The Connectar inspires students to actively participate in campus clubs,
                    organizations, and community service. By strengthening student networks, it
                    fosters leadership, collaboration, and a unified campus culture that enhances
                    the overall student experience.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Altruism and Cooperation--> */}
            <div className="col-md-6 form-group">
              <div className="benefits-col d-flex" data-aos="fade-right">
                <i className="mb-3 d-block">
                  <img src={communicationIcon} alt="" />
                </i>
                <div className="benefits-text">
                  <h3 className="mb-2">Promotion of Diversity and Inclusion</h3>
                  <p className="font text-left">
                    The Connectar breaks down barriers and empowers students to advocate for
                    inclusivity and social justice. By fostering connections among diverse groups,
                    it cultivates a campus environment where all voices are heard, supported, and
                    celebrated.
                  </p>
                </div>
              </div>
            </div>
            {/* <!--Communication Skills--> */}
          </div>
        </div>
      </section>
      <section className="section-widget">
        <div className="container">
          <div className="getInTouch text-center" data-aos="fade-right">
            <i className="d-block mb-3">
              <img src={Avatar} alt="" />
            </i>
            <h5 className="interFont">Still have questions?</h5>
            <p className="font ">
              If you have any questions, or you feel this may benefit your organisation; please
              reach out to us here.
            </p>
            <NavLink to="/student/contact" className="btn-Pink">
              Get in touch
            </NavLink>
          </div>
        </div>
      </section>
      <div data-aos="fade-right" id="footer">
        {" "}
        <Footer />{" "}
      </div>
    </>
  );
};

export default StudentMainPage;
