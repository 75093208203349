import React, { useEffect } from "react";
import productivityImage from "../assets/images/FrontPage/productiity.svg";
import employeeImage from "../assets/images/FrontPage/employee.svg";
import creativityImage from "../assets/images/FrontPage/creativity.svg";
import wellbeingImage from "../assets/images/FrontPage/wllbeing.svg";
import satisfactionImage from "../assets/images/FrontPage/satisfaction.svg";
import Reduced from "../assets/images/FrontPage/Reduced.png";
import Customer from "../assets/images/FrontPage/Customer.jpg";
import References from "../assets/images/FrontPage/References.jpg";
import Header from "./Header";
import Footer from "./Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import "MainComponet/css/custom.css";
const WhatTellUs = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scroll(0, 0);
      AOS.init({ duration: 500, delay: 50 });
      AOS.refreshHard();
      setTimeout(() => {
        document.getElementById("footer");
        AOS.refreshHard();
      }, 1000);
    }, 100);
  }, []);
  return (
    <>
      <Header />
      <section class="border1">
        <section class="container">
          <section class="row align-items-center">
            <section class="col-sm-12 bannerContent" data-aos="fade-right">
              <h2>
                {" "}
                What Does <span>The Data Tell Us?</span>{" "}
              </h2>
              <p className="font text-left">
                Workplace connections are vital for fostering a positive organizational culture,
                increasing producitvity,and promoting employee satisfaction. For those of you who
                are Interested, here are some statistics and reference to support this:
              </p>
            </section>
          </section>
        </section>
      </section>

      <section class="content-widget">
        <section class="container">
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={productivityImage} alt="" />
            </section>
            <section class="col-md-7">
              <h2>
                1. Increased <span className="cor-col">Productivity:</span>
              </h2>
              <ul className="font">
                <li>
                  {" "}
                  Research by Gallup found that highly engaged teams show 21% greater profitability
                  (Gallup, "State of the American Workplace Report," 2017).
                </li>
                <li>
                  {" "}
                  A study by MIT's Human Dynamics Laboratory discovered that teams that communicate
                  effectively are more productive. They found that the most productive teams
                  communicate in clusters, sharing ideas and thoughts (MIT Human Dynamics
                  Laboratory, 2012).
                </li>
                <li>
                  Another study by Gallup indicates that highly engaged teams experience 41% less
                  absenteeism and 17% higher productivity (Gallup, 2017).
                </li>
              </ul>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={employeeImage} alt="" />
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                2. Enhanced Employee <span className="cor-col">Engagement:</span>
              </h2>
              <ul className="font">
                <li>
                  A survey by Towers Watson revealed that organizations with high employee
                  engagement levels are 25% more profitable than those with low engagement levels
                  (Towers Watson, "Global Workforce Study," 2012).
                </li>
                <li>
                  {" "}
                  LinkedIn's Global Talent Trends report indicated that 94% of employees would stay
                  at a company longer if it invested in their career development (LinkedIn, "Global
                  Talent Trends 2020," 2020).
                </li>
                <li>
                  The Harvard Business Review reported that employees who have strong relationships
                  at work are more likely to stay with their employer for the long term (Harvard
                  Business Review, 2018).
                </li>
              </ul>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={creativityImage} alt="" />
            </section>
            <section class="col-md-7">
              <h2>
                3.Improved <span className="cor-col">Collaboration :</span>
              </h2>
              <ul className="font">
                <li>
                  {" "}
                  Research published in Harvard Business Review showed that employees who have
                  strong social connections at work are more likely to collaborate effectively
                  (Harvard Business Review, "The Impact of Employee Engagement on Performance,"
                  2016).
                </li>
                <li>
                  A study by McKinsey & Company found that productivity improves by 20-25% in
                  organizations with connected employees (McKinsey & Company, "The Social Economy,"
                  2012).
                </li>
              </ul>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={wellbeingImage} alt="" />
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                4. Higher Employee<span className="cor-col"> Satisfaction and Retention:</span>
              </h2>
              <ul className="font">
                <li>
                  The Society for Human Resource Management (SHRM) reported that 79% of employees
                  who quit their jobs cite lack of appreciation as their reason for leaving (SHRM,
                  "Employee Job Satisfaction and Engagement: Revitalizing a Changing Workforce,"
                  2019).
                </li>
                <li>
                  LinkedIn's Global Talent Trends report indicated that 70% of professionals in the
                  United States say they would not work at a leading company if it meant they had to
                  tolerate a bad workplace culture (LinkedIn, "Global Talent Trends 2019").
                </li>
              </ul>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={satisfactionImage} alt="" />
            </section>
            <section class="col-md-7">
              <h2>
                5. Boosted Innovation <span className="cor-col">and Creativity :</span>
              </h2>
              <ul className="font">
                <li>
                  A study published in the Journal of Applied Psychology found that socially
                  cohesive teams are more likely to generate innovative ideas (Journal of Applied
                  Psychology, "Social Network Ties and Creativity: The Role of Social Network Ties
                  in Group Idea Generation," 2011).
                </li>
                <li>
                  {" "}
                  Harvard Business Review reported that employees who feel connected to their
                  colleagues are more likely to share ideas and collaborate, leading to greater
                  innovation (Harvard Business Review, 2016).
                </li>
              </ul>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={Reduced} alt="" />
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                6. Reduced Stress and <span className="cor-col">Burnout:</span>
              </h2>
              <ul className="font">
                <li>
                  The American Institute of Stress reported that feeling isolated at work can
                  increase the risk of heart disease by 29% and the risk of stroke by 32% (American
                  Institute of Stress, "Workplace Stress," 2019).
                </li>
                <li>
                  {" "}
                  A study published in the Journal of Occupational Health Psychology found that
                  employees who have strong social support at work experience lower levels of stress
                  and burnout (Journal of Occupational Health Psychology, "Social Support at Work
                  and in the Family: Dual-Pathway Associations with Sleep Quality and Sleep
                  Duration," 2017).
                </li>
              </ul>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={creativityImage} alt="" />
            </section>
            <section class="col-md-7">
              <h2>
                7. Improved <span className="cor-col"> Mental Health:</span>
              </h2>
              <ul className="font">
                <li>
                  {" "}
                  The World Health Organization (WHO) estimates that depression and anxiety cost the
                  global economy $1 trillion per year in lost productivity (World Health
                  Organization, "Depression and Other Common Mental Disorders: Global Health
                  Estimates," 2017).
                </li>
                <li>
                  A study published in the Journal of Business and Psychology found that employees
                  who feel connected to their colleagues report better mental health outcomes
                  (Journal of Business and Psychology, "The Importance of Belongingness in the
                  Workplace," 2013).
                </li>
              </ul>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center order-md-2">
              <img src={Customer} alt="" height="300px" width="400px" />
            </section>
            <section class="col-md-7 order-md-1">
              <h2>
                8. Customer <span className="cor-col"> Satisfaction:</span>
              </h2>
              <ul className="font">
                <li>
                  A study conducted by Deloitte found that organizations with a strong sense of
                  belonging and connectivity among employees have 3.5 times higher levels of
                  customer satisfaction (Deloitte, 2019).
                </li>
              </ul>
            </section>
          </section>
          <section class="row align-items-center " data-aos="fade-right">
            <section class="col-md-5 text-center">
              <img src={References} alt="" />
            </section>
            <section class="col-md-7">
              <h2>References:</h2>
              <ul className="font">
                <li>Gallup. "State of the American Workplace Report," 2017.</li>
                <li> MIT Human Dynamics Laboratory, 2012.</li>
                <li>Towers Watson. "Global Workforce Study," 2012.</li>
                <li>
                  Harvard Business Review. "The Impact of Employee Engagement on Performance," 2016.
                </li>
                <li> McKinsey & Company. "The Social Economy," 2012.</li>
                <li>
                  SHRM. "Employee Job Satisfaction and Engagement: Revitalizing a Changing
                  Workforce," 2019.
                </li>
                <li>LinkedIn. "Global Talent Trends 2020," 2020.</li>
                <li>
                  Journal of Applied Psychology. "Social Network Ties and Creativity: The Role of
                  Social Network Ties in Group Idea Generation," 2011.
                </li>
                <li>
                  Journal of Occupational Health Psychology. "Social Support at Work and in the
                  Family: Dual-Pathway Associations with Sleep Quality and Sleep Duration," 2017.
                </li>
                <li>
                  World Health Organization. "Depression and Other Common Mental Disorders: Global
                  Health Estimates," 2017.
                </li>
                <li>
                  {" "}
                  Journal of Business and Psychology. "The Importance of Belongingness in the
                  Workplace," 2013.
                </li>
                <li>
                  Deloitte. "The Employee Experience: It’s Time We All Take It Seriously", 2019.
                </li>
              </ul>
            </section>
          </section>
        </section>
      </section>

      <section class="section-widget">
        <section class="container">
          <section class="getInTouch text-center mt-5" data-aos="fade-right">
            <p className="font">
              We hope these statistics help, let us know if there are any others we might have
              misses that you'd like included.
            </p>
            <a href="/corporate/contact" class="btn btn-primary">
              Get in touch
            </a>
          </section>
        </section>
      </section>

      <div data-aos="fade-right" id="footer">
        {" "}
        <Footer />{" "}
      </div>
    </>
  );
};

export default WhatTellUs;
