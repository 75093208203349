import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Logo from "assets/images/small-logos/Logo-removebg-preview.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'MainComponet/css/custom.css';
import "MainComponet/Confrence/event.css"
import "MainComponet/Students/Studentevent.css"
const Header = () => {
  const location = useLocation();

  useEffect(() => {
    AOS.init({ duration: 3000 });
    window.scrollTo(0, 0);
  }, [location]);
  // const isCorporateRoutestart =  location.pathname !== ('/corporate');
  // const isStudentRouteStart =  location.pathname !== ('/student');
  // const isConferenceRouteStart = location.pathname !== ('/conference');

  const isCorporateRoute = location.pathname.startsWith('/corporate');
  const isConferenceRoute = location.pathname.startsWith('/conference');
  const isStudentRoute = location.pathname.startsWith('/student');

  let contactRoute = "/contact";
  if (isConferenceRoute) {
    contactRoute = "/conference/contact";
  } else if (isCorporateRoute) {
    contactRoute = "/corporate/contact";
  } else if (isStudentRoute) {
    contactRoute = "/student/contact";
  }
  let loginRoute = "/login"; 
  if (isCorporateRoute) {
    loginRoute = "/corporate/login";
  } else if (isConferenceRoute) {
    loginRoute = "/conference/login";
  } else if (isStudentRoute) {
    loginRoute = "/student/login";
  }

  return (
    <>
      <section className="circle circle1"></section>
      <section className="circle circle2"></section>
      <header id="header">
        <nav className="navbar navbar-expand-lg fixed-top navbar-light" style={{ backgroundColor: '#ffffff', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
          <div className="container" data-aos="fade-down">
          {/* {isCorporateRoutestart && isConferenceRouteStart && isStudentRouteStart ? (
              <NavLink
                to={isCorporateRoute ? "/corporate" : isConferenceRoute ? "/conference" : "/student"}
                className="navbar-brand"
              >
                <img src={Logo} alt="The Connectar" height="300px" width="300px" />
              </NavLink>
            ) : ( */}
              <NavLink to="/" className="navbar-brand">
                <img src={Logo} alt="The Connectar" height="300px" width="300px" />
              </NavLink>
            {/* )} */}


            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 font">
                {/* Dynamic Navigation Links */}
                <li className="nav-item">
                  <NavLink  className={({ isActive }) =>
                    isActive && isStudentRoute
                      ? "nav-link active-link"
                      : isActive && isCorporateRoute
                      ? "nav-link active-blue"
                      : isActive
                      ? "nav-link active-default"
                      : "nav-link"
                  } to={isCorporateRoute ? "/corporate/home" : isConferenceRoute ? "/conference/home" : "/student/home"}>
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink exact activeClassName="active" className="nav-link" to={isCorporateRoute ? "/corporate/why-the-connectar" : isConferenceRoute ? "/conference/why-the-connectar" : "/student/why-the-connectar"}>
                    Why Connectar
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink  
                   className={({ isActive }) =>
                    isActive && isStudentRoute
                      ? "nav-link active-link"
                      : isActive && isCorporateRoute
                      ? "nav-link active-blue"
                      : isActive
                      ? "nav-link active-default"
                      : "nav-link"
                  }
                  to={isCorporateRoute ? "/corporate/why-you-should" : isConferenceRoute ? "/conference/why-you-should" : "/student/why-you-should"}
               >
                    Data Insights
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink activeClassName="active" className="nav-link" to={isCorporateRoute ? "/corporate/methodology" : isConferenceRoute ? "/conference/methodology" : "/student/methodology"}>
                     Methodology
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink activeClassName="active" className="nav-link" to={isCorporateRoute ? "/corporate/whynow" : isConferenceRoute ? "/conference/whynow" : "/student/whynow"}>
                    Why Act Now
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className={({ isActive }) => 
                       isActive 
                  ? (isConferenceRoute ? "nav-link active-success" : isStudentRoute ? "nav-link active-link" : isCorporateRoute ? "nav-link active-default" : "nav-link") 
                  : "nav-link"
              } to={isCorporateRoute ? "/corporate/blogs" : isConferenceRoute ? "/conference/blogs" : "/student/blogs"}>
                  Blogs
                  </NavLink>
                </li>
                {/* Contact Us link */}
                <li className="nav-item">
                  <NavLink className={({ isActive }) => 
                       isActive 
                  ? (isConferenceRoute ? "nav-link active-success" : isStudentRoute ? "nav-link active-link" : isCorporateRoute ? "nav-link active-default" : "nav-link") 
                  : "nav-link"
              } to={contactRoute}>
                    Contact 
                  </NavLink>
                </li>
              </ul>
              <div className="d-flex" role="search">
                <NavLink className={({ isActive }) => {
    return `btn me-3 ${
      isStudentRoute
        ? "btn-outline-pink"
        : isCorporateRoute
        ? "btn-outline-primary"
        : isConferenceRoute
        ? "btn-outline-success"
        : "btn-outline-primary"
    } ${isActive ? "active-btn" : ""}`;
  }} to={loginRoute}>
                  Login
                </NavLink>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;
