import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "MainComponet/css/custom.css";
const GetTounch = () => {
  useEffect(() => {
    AOS.init({ duration: 3000 });
  }, []);

  return (
    <>
      <section class="section-widget">
        <section class="container">
          <section class="getInTouch text-center " data-aos="fade-right">
            <p className="font">
              If you have any questions, or you feel this may benefit your organisation; please
              reach out to us here.
            </p>
              <NavLink to="/student/contact" className="btn-Pink" >
              Get in touch
                          </NavLink>
          </section>
        </section>
      </section>
    </>
  );
};

export default GetTounch;
